import React, { useState } from "react";
import "../styles/StockMaintenance.css";

const StockMaintenance = () => {
  const [stockItems, setStockItems] = useState([]);
  const [item, setItem] = useState({ name: "", quantity: 0 });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setItem({ ...item, [name]: value });
  };

  const addItem = () => {
    if (item.name && item.quantity > 0) {
      setStockItems([
        ...stockItems,
        { ...item, id: Date.now(), remark: "" }, // Add remark field
      ]);
      setItem({ name: "", quantity: 0 });
    } else {
      alert("Please enter valid item details.");
    }
  };

  const updateQuantity = (id, newQuantity) => {
    setStockItems(
      stockItems.map((stockItem) =>
        stockItem.id === id
          ? { ...stockItem, quantity: newQuantity }
          : stockItem
      )
    );
  };

  const updateRemark = (id, newRemark) => {
    setStockItems(
      stockItems.map((stockItem) =>
        stockItem.id === id ? { ...stockItem, remark: newRemark } : stockItem
      )
    );
  };

  const removeItem = (id) => {
    setStockItems(stockItems.filter((stockItem) => stockItem.id !== id));
  };

  return (
    <div className="stock-clearance-container">
      <h1>Cricket Academy Stock Maintenance</h1>
      <div className="stock-clearance-input-section">
        <input
          type="text"
          name="name"
          placeholder="Item Name"
          value={item.name}
          onChange={handleInputChange}
        />
        <input
          type="number"
          name="quantity"
          placeholder="Quantity"
          value={item.quantity}
          onChange={handleInputChange}
        />
        <button onClick={addItem}>Add Item</button>
      </div>
      <div className="stock-clearance-list">
        {stockItems.length > 0 ? (
          stockItems.map((stockItem) => (
            <div key={stockItem.id} className="stock-clearance-item">
              <span>{stockItem.name}</span>
              <input
                type="number"
                value={stockItem.quantity}
                onChange={(e) =>
                  updateQuantity(stockItem.id, parseInt(e.target.value, 10))
                }
                min="0"
              />
              <input
                type="text"
                placeholder="Remark"
                value={stockItem.remark}
                onChange={(e) => updateRemark(stockItem.id, e.target.value)}
              />
              <button onClick={() => removeItem(stockItem.id)}>Remove</button>
            </div>
          ))
        ) : (
          <p>No items in stock.</p>
        )}
      </div>
    </div>
  );
};

export default StockMaintenance;
