import React from "react";
import "../styles/AddAuctionCategory.css";

function AddAuctionCategory() {
  return (
    <div className="add-category-container">
      <h1 className="add-category-title">Create Category</h1>
      <form className="add-category-form">
        <div className="form-group">
          <label htmlFor="category-name" className="form-label">
            Category Name <span>*</span>
          </label>
          <input
            type="text"
            id="category-name"
            className="form-input"
            placeholder="Category Name"
          />
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="max-players" className="form-label">
              Max Players (Per Team)
            </label>
            <input
              type="number"
              id="max-players"
              className="form-input"
              placeholder="0"
            />
          </div>
          <div className="form-group">
            <label htmlFor="min-players" className="form-label">
              Min Players
            </label>
            <input
              type="number"
              id="min-players"
              className="form-input"
              placeholder="0"
            />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="base-bid" className="form-label">
            Base Bid
          </label>
          <input
            type="number"
            id="base-bid"
            className="form-input"
            placeholder="0"
          />
        </div>
        <div className="form-group">
          <label htmlFor="increment" className="form-label">
            Increment <span>(click to multiply increment)</span>
          </label>
          <input
            type="number"
            id="increment"
            className="form-input"
            placeholder="0"
          />
        </div>
        <div className="form-group">
          <label htmlFor="reserve-bid" className="form-label">
            Reserve Bid
          </label>
          <input
            type="number"
            id="reserve-bid"
            className="form-input"
            placeholder="0"
          />
        </div>
        <div className="form-group">
          <label htmlFor="highest-bid" className="form-label">
            Highest Bid <span>per Player</span>
          </label>
          <input
            type="number"
            id="highest-bid"
            className="form-input"
            placeholder="0"
          />
        </div>
        <div className="form-group">
          <label htmlFor="category-highest-bid" className="form-label">
            Category Highest Bid <span>for All Players</span>
          </label>
          <input
            type="number"
            id="category-highest-bid"
            className="form-input"
            placeholder="0"
          />
        </div>
        <div className="form-group">
          <label htmlFor="category-color" className="form-label">
            Category Color
          </label>
          <input
            type="text"
            id="category-color"
            className="form-input"
            placeholder="Category Color"
          />
        </div>
        <div className="form-actions">
          <button type="button" className="btn save-btn">
            Save and Add New
          </button>
          <button type="submit" className="btn add-btn">
            Add Category
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddAuctionCategory;
