import React, { useEffect, useState } from "react";
import "../styles/AuctionTeam.css";

import { Delete, Edit } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Breadcrumbs from "../components/Breadcrumbs";

const AuctionTeam = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const id = pathSegments[pathSegments.length - 1];
  const [team, setTeam] = useState([]);
  const [counter, setCounter] = useState(0);
  const [selectedTeam, setSelectedTeam] = useState([]);

  useEffect(() => {
    TeamList();
  }, []);

  useEffect(() => {
    TeamAddAuction();
  }, [counter]);

  const TeamList = () => {
    axios({
      url: "https://lunarsenterprises.com:3007/cricket/tour-team/list",
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((data) => {
        if (data.data.result === true) {
          setTeam(data.data.list);
        } else {
          console.log("not working");
        }
      })
      .catch((err) => {
        console.error("Error fetching teams:", err);
      });
  };
  const TeamAddAuction = () => {
    axios({
      url: "https://lunarsenterprises.com:3007/cricket/list-auc/team",
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        auc_id: JSON.parse(id),
      },
    })
      .then((data) => {
        if (data.data.result === true) {
          console.log(data.data, "dataaaa");

          setSelectedTeam(data.data.list);
        } else {
          console.log("not working");
        }
      })
      .catch((err) => {
        console.error("Error fetching teams:", err);
      });
  };
  const AuctionTeamList = (teamId) => {
    axios({
      url: "https://lunarsenterprises.com:3007/cricket/add-auc/team",
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        user_id: teamId,
        auc_id: id,
      },
    })
      .then((data) => {
        if (data.data.result === true) {
          toast.success(data.data.message);
          setCounter(counter + 1);
        } else {
          console.log("not working");
        }
      })
      .catch((err) => {
        console.error("Error fetching teams:", err);
      });
  };

  const handleAddTeam = (event) => {
    const teamId = event.target.value;
    if (teamId) {
      AuctionTeamList(teamId);

      // console.log(`Added team: ${teamName}`);
    }
  };
  const baseurl = "https://lunarsenterprises.com:3007/";
  return (
    <div className="auction-team-container-unique">
      <Breadcrumbs />
      {/* <h1 className="auction-title-unique">T10 CRICKET LEAGUE</h1> */}
      <header className="auction-header-unique">
        <h2 className="auction-subtitle-unique">TEAM</h2>
        <div className="auction-actions-unique">
          <select
            className="auction-dropdown-unique"
            onChange={handleAddTeam}
            value=""
          >
            <option value="" disabled>
              + Add Team
            </option>
            {team.map((team, index) => (
              <option key={team.t_id} value={team.t_id}>
                {team.t_name}
              </option>
            ))}
          </select>
        </div>
      </header>
      <div className="team-cards-container-unique">
        {selectedTeam.length ? (
          selectedTeam?.map((team) => (
            <div className="team-card-unique" key={team.t_id}>
              <img
                src={baseurl + team.t_logo}
                alt={team.t_name}
                className="team-logo-unique"
              />
              <h3 className="team-name-unique">{team.t_name}</h3>
              <div className="team-details-unique">
                <p className="team-detail-unique">S-Name: {team.t_name}</p>
                {/* <p className="team-detail-unique">S-Key: {team.shortKey}</p> */}
              </div>
              <div className="team-actions-unique">
                <button
                  className="edit-button-unique"
                  onClick={() => navigate(`/AuctionTeamEdit/${team.t_id}`)}
                >
                  <Edit />
                </button>
                {/* <button className="delete-button-unique">
                  <Delete />
                </button> */}
              </div>
            </div>
          ))
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            No Team Available
          </div>
        )}
      </div>
    </div>
  );
};

export default AuctionTeam;
