import React, { useState, useEffect } from "react";
import axios from "axios";
import "../styles/SpinDetailsForm.css";
import { useNavigate } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import { toast } from "react-toastify";

const SpinDetailsForm = ({ history }) => {
  const navigate = useNavigate();
  const [team, setTeam] = useState("");
  const [tornament, setTornament] = useState("");
  const [players, setPlayers] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectAllUnsold, setSelectAllUnsold] = useState(false);
  const [unsold, setUnsold] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [teamOptions, setTeamOptions] = useState([]);
  const [torOptions, setTorOptions] = useState([]);

  useEffect(() => {
    fetchTeamList();

    fetchTornamList();
  }, []);
  useEffect(() => {
    fetchPlayerList();
  }, [unsold, tornament]);
  const fetchTeamList = () => {
    axios({
      url: "https://lunarsenterprises.com:3007/cricket/team/list",
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.data.result === true) {
          console.log(response.data, "dataaaa");

          const formattedOptions = response.data.list.map((team) => ({
            label: team.t_name,
            value: team.t_id,
          }));
          setTeamOptions(formattedOptions);
          console.log("Fetched teamsssss:", formattedOptions);
        } else {
          console.log("Failed to fetch teams");
        }
      })
      .catch((err) => {
        console.error("Error fetching teams:", err);
      });
  };
  const fetchTornamList = () => {
    axios({
      url: "https://lunarsenterprises.com:3007/cricket/list/tour",
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.data.result === true) {
          const formattedOptions = response.data.list.map((team) => ({
            label: team.tr_name,
            value: team.tr_id,
          }));
          setTorOptions(formattedOptions);
          console.log("Fetched teams:", formattedOptions);
        } else {
          console.log("Failed to fetch teams");
        }
      })
      .catch((err) => {
        console.error("Error fetching teams:", err);
      });
  };

  const fetchPlayerList = () => {
    axios({
      url: "https://lunarsenterprises.com:3007/cricket/individual/list",
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        i_main_type: tornament,
        i_sale_status: unsold,
      },
    })
      .then((data) => {
        if (data.data.result === true) {
          const Members = data.data.list.map((item) => ({
            label: item.i_name,
            value: item.i_id,
          }));
          setPlayers({ member: Members });
        }
      })
      .catch((err) => {
        console.error("Error fetching players:", err);
      });
  };

  const handleSelectAllChange = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      const allPlayerIds = players.member.map((player) => player.value);
      setTeamMembers(allPlayerIds);
    } else {
      setTeamMembers([]);
    }
  };
  const handleSelectAllChangeUnsold = () => {
    setSelectAllUnsold(!selectAllUnsold);
    setUnsold("unsold");
    if (!selectAllUnsold) {
      const allPlayerIds = players.member.map((player) => player.value);
      setTeamMembers(allPlayerIds);
    } else {
      setTeamMembers([]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (teamMembers.length > 0) {
      const matchDetails = {
        user_id: localStorage.getItem("USER_ID"),
        team,
        date,
        time,
        member: teamMembers,
        tr_id: tornament,
      };
      console.log(matchDetails, "matchDetails");

      axios({
        url: "https://lunarsenterprises.com:3007/cricket/create/spin",
        method: "post",
        data: matchDetails,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.data.result === true) {
            console.log("Match details submitted successfully");
            navigate("/Spin");
          } else {
            console.log("Failed to submit match details");
          }
        })
        .catch((err) => {
          console.error("Error submitting match details:", err);
        });
    } else {
      toast.error("Please select players");
    }
  };

  const handleSelect = (e) => {
    const selectedMember = e.map((el) => el.value);
    setTeamMembers(selectedMember);
  };

  return (
    <div className="match-form-container">
      <h2>Enter Spin Details</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Tournament:</label>
          <select
            className="custom-select"
            value={tornament}
            onChange={(e) => setTornament(e.target.value)}
            required
          >
            <option value="" disabled>
              Select Tournament
            </option>
            {torOptions.map((teamName, index) => (
              <option key={index} value={teamName.value}>
                {teamName.label}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>Team:</label>
          <select
            className="custom-select"
            value={team}
            onChange={(e) => setTeam(e.target.value)}
            required
          >
            <option value="" disabled>
              Select Team
            </option>
            {teamOptions.map((teamName, index) => (
              <option key={index} value={teamName.value}>
                {teamName.label}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>Date:</label>
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Time:</label>
          <input
            style={{ width: "90%" }}
            type="time"
            value={time}
            onChange={(e) => setTime(e.target.value)}
            required
          />
        </div>
        <div className="multiselect-container">
          <label>Select Members:</label>
          <div style={{ display: "flex" }}>
            <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAllChange}
            />
            <label>Select All</label>
          </div>
        </div>
        <div>
          <label>Unsold Members:</label>
          <div style={{ display: "flex" }}>
            <input
              type="checkbox"
              checked={selectAllUnsold}
              onChange={handleSelectAllChangeUnsold}
            />
            <label>Select All</label>
          </div>
        </div>
        <div className="full-width">
          <Multiselect
            options={players.member}
            selectedValues={players.member?.filter((player) =>
              teamMembers.includes(player.value)
            )}
            displayValue="label"
            onSelect={handleSelect}
            onRemove={handleSelect}
            isObject
          />
        </div>
        <div className="full-width">
          <button type="submit">Submit Spin Details</button>
        </div>
      </form>
    </div>
  );
};

export default SpinDetailsForm;
