import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import "../styles/Tournament.css";
import { Edit3, Trash2, MoreVertical } from "react-feather";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConfirmationModelTour from "../components/ConfirmationModalTour";

const Tournament = () => {
  const [activeTab, setActiveTab] = useState("upcoming");
  const [matches, setMatches] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedTournamentId, setSelectedTournamentId] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [dropdownMatchId, setDropdownMatchId] = useState(null);
  const [modalStyle, setModalStyle] = useState({});
  const buttonRefs = useRef({});
  const [type, setType] = useState("");

  useEffect(() => {
    TournamentList();
  }, []);

  const today = new Date().toISOString().split("T")[0];
  const upcomingMatches = matches.filter((match) => {
    const startDate = new Date(match?.tr_start_date)
      .toISOString()
      .split("T")[0];
    const endDate = new Date(match?.tr_end_date).toISOString().split("T")[0];
    return startDate >= today || (startDate <= today && endDate >= today);
  });

  const pastMatches = matches.filter((match) => match?.tr_end_date < today);

  const handleDelete = (response) => {
    if (response.isConfirm) {
      axios
        .post("https://lunarsenterprises.com:3007/cricket/delete/tournament", {
          tr_id: selectedTournamentId,
        })
        .then((data) => {
          if (data.data.result === true) {
            toast.success(data.data?.message);
            TournamentList();
          } else {
            toast.error(data.data?.message);
          }
        })
        .catch((err) => {
          console.error("Error deleting tournament:", err);
        });
    }
    setShowConfirmModal(false);
  };

  const TournamentList = () => {
    axios
      .post("https://lunarsenterprises.com:3007/cricket/list/tour")
      .then((data) => {
        if (data.data.result === true) {
          setMatches(data.data.list);
        } else {
          console.error("Failed to fetch tournaments");
        }
      })
      .catch((err) => {
        console.error("Error fetching tournaments:", err);
      });
  };
  const downloadpdf = () => {
    axios({
      url: "https://lunarsenterprises.com:3007/cricket/individual/list",
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        i_main_type: dropdownMatchId,
        // i_sale_status: selectedRole,
        format: "pdf",
      },
    })
      .then((data) => {
        if (data.data.result === true) {
          // toast.info(data.data.message);
          onButtonClick(data.data.pdf);
        } else {
          toast.error(data.data.message);
          console.log("not working");
        }
      })
      .catch((err) => {
        console.error("Error fetching teams:", err);
      });
  };
  const downloadexcel = () => {
    axios({
      url: "https://lunarsenterprises.com:3007/cricket/individual/list",
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        i_main_type: dropdownMatchId,
        // i_sale_status: selectedRole,
        format: "excel",
      },
    })
      .then((data) => {
        if (data.data.result === true) {
          onButtonClickExcel(data.data.excel);
          // toast.info(data.data.message);
        } else {
          console.log("not working");
          toast.error(data.data.message);
        }
      })
      .catch((err) => {
        console.error("Error fetching teams:", err);
      });
  };
  const handleDownload = (format) => {
    // toast.info(`Downloading ${format}...`);
    if (format == "PDF") {
      downloadpdf();
    } else {
      downloadexcel();
    }
    setModalVisible(false);
  };
  const onButtonClick = async (data) => {
    try {
      const response = await axios.get(data, {
        responseType: "blob",
      });

      const pdfBlob = new Blob([response.data], { type: "application/pdf" });

      const url = window.URL.createObjectURL(pdfBlob);

      const tempLink = document.createElement("a");
      tempLink.href = url;
      tempLink.setAttribute("download", `Registeration.pdf`);
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };
  const onButtonClickExcel = async (data) => {
    console.log(data, "data");

    if (data) {
      const headers = { "Content-Type": "blob" };
      const config = {
        method: "GET",
        url: data,
        responseType: "arraybuffer",
        headers,
      };

      try {
        const response = await axios(config);
        const outputFilename = `Registration${Date.now()}.xls`;
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", outputFilename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error downloading the file:", error);
      }
    }
  };
  const toggleDropdownModal = (matchId, event) => {
    console.log(matchId, "matchId");

    if (dropdownMatchId === matchId && modalVisible) {
      setModalVisible(false);
      setDropdownMatchId(null);
      return;
    }

    setDropdownMatchId(matchId);

    const button = buttonRefs.current[matchId];
    if (button) {
      const rect = button.getBoundingClientRect();
      setModalStyle({
        top: rect.bottom + window.scrollY + "px",
        left: rect.left + window.scrollX + "px",
      });
    }

    setModalVisible(true);
  };
  const onDeleteConfirm = (playerId) => {
    console.log(playerId, "selectedPlayerId");

    setSelectedTournamentId(playerId);
    setShowConfirmModal(true);
  };
  return (
    <div className="tournament-container">
      <ToastContainer autoClose={3000} theme={"light"} />
      <div className="tournament-header">
        <h1>Tournament</h1>
        <Link to="/Tournament/TournamentAdd">
          <button className="tournament-button">Create New Tournament</button>
        </Link>
      </div>

      <div className="tournament-tab-nav">
        <button
          className={activeTab === "upcoming" ? "tournament-active-tab" : ""}
          onClick={() => setActiveTab("upcoming")}
        >
          Upcoming Tournament
        </button>
        <button
          className={activeTab === "past" ? "tournament-active-tab" : ""}
          onClick={() => setActiveTab("past")}
        >
          Past Tournament
        </button>
      </div>

      <div className="tournament-matches-container">
        {activeTab === "upcoming" ? (
          upcomingMatches.length === 0 ? (
            <div className="tournament-no-matches">
              <p>No upcoming matches available.</p>
            </div>
          ) : (
            upcomingMatches.map((match) => (
              <div className="tournament-match-card" key={match.tr_id}>
                <h3>{match.tr_name}</h3>
                <p>
                  Start Date:{" "}
                  {new Date(match.tr_start_date).toLocaleDateString()}
                </p>
                <p>
                  End Date: {new Date(match.tr_end_date).toLocaleDateString()}
                </p>
                <p>Location: {match.tr_place}</p>
                <div className="tournament-edit-delete-icons">
                  <Link state={match} to={`/TournamentEdit/${match.tr_id}`}>
                    <button className="tournament-edit-btn">
                      <Edit3 />
                    </button>
                  </Link>
                  <button
                    className="tournament-delete-btn"
                    onClick={() => onDeleteConfirm(match.tr_id)}
                  >
                    <Trash2 />
                  </button>
                  <div className="tournament-edit-delete-icons">
                    <button
                      ref={(el) => (buttonRefs.current[match.tr_id] = el)}
                      className="tournament-options-btn"
                      onClick={(event) =>
                        toggleDropdownModal(match.tr_id, event)
                      }
                    >
                      <MoreVertical />
                    </button>
                  </div>
                </div>
              </div>
            ))
          )
        ) : pastMatches.length === 0 ? (
          <p>No past matches available.</p>
        ) : (
          pastMatches.map((match) => (
            <div className="tournament-match-card" key={match.tr_id}>
              <h3>{match.tr_name}</h3>
              <p>
                Start Date: {new Date(match.tr_start_date).toLocaleDateString()}
              </p>
              <p>
                End Date: {new Date(match.tr_end_date).toLocaleDateString()}
              </p>
              <p>Location: {match.tr_place}</p>
              <div className="tournament-edit-delete-icons">
                <Link state={match} to={`/TournamentEdit/${match.tr_id}`}>
                  <button className="tournament-edit-btn">
                    <Edit3 />
                  </button>
                </Link>
                <button
                  className="tournament-delete-btn"
                  onClick={() => onDeleteConfirm(match.tr_id)}
                >
                  <Trash2 />
                </button>
                <div className="tournament-edit-delete-icons">
                  <button
                    ref={(el) => (buttonRefs.current[match.tr_id] = el)}
                    className="tournament-options-btn"
                    onClick={(event) => toggleDropdownModal(match.tr_id, event)}
                  >
                    <MoreVertical />
                  </button>
                </div>
              </div>
            </div>
          ))
        )}
      </div>

      {modalVisible && (
        <>
          <div
            className="modal-overlay"
            onClick={() => setModalVisible(false)}
          />
          <div className="dropdown-modal" style={modalStyle}>
            <p onClick={() => handleDownload("PDF")}>Download PDF</p>
            <p onClick={() => handleDownload("Excel")}>Download Excel</p>
          </div>
        </>
      )}
      {showConfirmModal && (
        <ConfirmationModelTour
          id={selectedTournamentId}
          callback={handleDelete}
          onClose={() => setShowConfirmModal(false)}
        />
      )}
    </div>
  );
};

export default Tournament;
