import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/MatchForm.css";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const TournamentAdd = () => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [name, setName] = useState("");
  const [endDate, setEnddate] = useState("");
  const [date, setDate] = useState("");
  const [location, setLocation] = useState("");
  const [type, setType] = useState("");
  const [file, setFile] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    // let requestBody = {
    //   tour_name: name,
    //   start_date: date,
    //   end_date: endDate,
    //   place: location,
    //   tour_type: type,
    //   image: file,
    // };
    const formdata = new FormData();
    formdata.append("tour_name", name);
    formdata.append("start_date", date);
    formdata.append("end_date", endDate);
    formdata.append("place", location);
    formdata.append("tour_type", type);
    formdata.append("image", file);
    axios({
      url: "https://lunarsenterprises.com:3007/cricket/add/tournament",
      method: "post",
      data: formdata,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    })
      .then((data) => {
        if (data.data.result === true) {
          navigate("/Tournament");
          console.log("Match created successfully");
          toast.success(data.data.message);
        } else {
          console.log("Failed to create match");
        }
      })
      .catch((err) => {
        console.error("Error fetching teams:", err);
      });
  };
  const handleImageChange = (e) => {
    console.log(e.target.files[0], "aa svkcnaskvn");

    const file = e.target.files[0];
    setFile(file);
  };
  return (
    <div className="match-form-container">
      <ToastContainer autoClose={3000} theme={"light"} />
      <h2>Create New Tournament</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Tournament Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Start Date:</label>
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            required
          />
        </div>
        <div>
          <label>End Date:</label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEnddate(e.target.value)}
            required
          />
        </div>

        <div>
          <label>Location:</label>
          <input
            type="text"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Type:</label>
          <input
            type="text"
            value={type}
            onChange={(e) => setType(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Tournament banner:</label>
          <input
            accept="image/*"
            type="file"
            onChange={handleImageChange}
            required
          />
        </div>
        <div className="full-width">
          <button type="submit">Create Match</button>
        </div>
      </form>
    </div>
  );
};

export default TournamentAdd;
