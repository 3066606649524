import React, { useState } from "react";
import "../styles/DashboardPage.css";

function DashboardPage() {
  const stats = [
    { title: "Total Matches", value: 120 },
    { title: "Registered Players", value: 240 },
    { title: "Upcoming Events", value: 5 },
    { title: "Teams", value: 10 },
  ];

  const batches = {
    Morning: [
      { name: "John Doe", profile: "/upload/" },
      { name: "Jane Smith", profile: "/upload/" },
      { name: "Emily Johnson", profile: "/upload/" },
    ],
    Evening: [
      { name: "Michael Brown", profile: "/upload/" },
      { name: "Sarah Davis", profile: "/upload/" },
      { name: "Paul Walker", profile: "/upload/" },
    ],
    Holiday: [
      { name: "Anna Taylor", profile: "/upload/" },
      { name: "Chris Martin", profile: "/upload/" },
      { name: "James Wilson", profile: "/upload/" },
    ],
    Regular: [
      { name: "Anna Taylor", profile: "/upload/" },
      { name: "Chris Martin", profile: "/upload/" },
      { name: "James Wilson", profile: "/upload/" },
    ],
  };

  const [activeBatch, setActiveBatch] = useState("Morning");
  const [attendance, setAttendance] = useState(
    Object.fromEntries(
      Object.values(batches)
        .flat()
        .map((student) => [student.name, { status: "neutral", late: false }])
    )
  );

  const toggleAttendance = (student, status) => {
    setAttendance((prev) => ({
      ...prev,
      [student]: {
        ...prev[student],
        status: prev[student].status === status ? "neutral" : status,
      },
    }));
  };

  const toggleLate = (student) => {
    setAttendance((prev) => ({
      ...prev,
      [student]: {
        ...prev[student],
        late: !prev[student].late,
      },
    }));
  };

  return (
    <div className="dashboard">
      <div className="card-grid">
        {stats.map((stat, index) => (
          <div
            key={index}
            className={`card ${
              typeof stat.value === "string" && stat.value.includes("%")
                ? "highlight"
                : ""
            }`}
          >
            <h2>{stat.title}</h2>
            <p>{stat.value}</p>
          </div>
        ))}
      </div>

      <div className="attendance-section">
        <h2>Student Attendance</h2>

        <div className="batch-tabs">
          {Object.keys(batches).map((batch) => (
            <button
              key={batch}
              className={`tab-button ${activeBatch === batch ? "active" : ""}`}
              onClick={() => setActiveBatch(batch)}
            >
              {batch} Batch
            </button>
          ))}
        </div>

        <ul className="student-list">
          {batches[activeBatch].map((student, index) => (
            <li key={index} className="student-item">
              <div className="student-info">
                <span>{index + 1}.</span>
                <img
                  src={student.profile}
                  alt={`${student.name}'s profile`}
                  className="student-profile"
                />
                <span>{student.name}</span>
              </div>
              <div className="attendance-controls">
                <div
                  className={`attendance-slider ${
                    attendance[student.name].status
                  }`}
                >
                  <span
                    className="slider-label absent-label"
                    onClick={() => toggleAttendance(student.name, "absent")}
                  >
                    Absent
                  </span>
                  <span
                    className="slider-label present-label"
                    onClick={() => toggleAttendance(student.name, "present")}
                  >
                    Present
                  </span>
                  <div className="slider-button"></div>
                </div>
                <label className="attendance-late-checkbox">
                  <input
                    type="checkbox"
                    checked={attendance[student.name].late}
                    onChange={() => toggleLate(student.name)}
                  />
                  Late
                </label>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default DashboardPage;
