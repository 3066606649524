import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../styles/MatchForm.css";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";

const EditMatch = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [teamA, setTeamA] = useState("");
  const [teamB, setTeamB] = useState("");
  const [date, setDate] = useState("");
  const [location, setLocation] = useState("");
  const [time, setTime] = useState("");
  const [type, setType] = useState("");
  const [options, setOptions] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    TeamList();
    MatchView();
  }, [id]);

  // Fetch team list API
  const TeamList = () => {
    axios({
      url: "https://lunarsenterprises.com:3007/cricket/team/list",
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.data.result === true) {
          setOptions(response.data.list);
        } else {
          console.log("Failed to fetch teams");
        }
      })
      .catch((err) => {
        console.error("Error fetching teams:", err);
      });
  };
  const MatchView = () => {
    axios({
      url: "https://lunarsenterprises.com:3007/cricket/view/match",
      data: {
        m_id: id,
      },
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.data.result === true) {
          setTeamA(response.data.list[0].m_team1);
          setTeamB(response.data.list[0].m_team2);
          setDate(response.data.list[0].m_date);
          setLocation(response.data.list[0].m_location);
          setTime(response.data.list[0].m_time);
          setType(response.data.list[0].m_type);
        } else {
          console.log("Failed to fetch teams");
        }
      })
      .catch((err) => {
        console.error("Error fetching teams:", err);
      });
  };
  const handleSubmit = (e) => {
    setLoader(true);
    e.preventDefault();
    const updatedMatch = {
      m_id: parseInt(id),
      team1: teamA,
      team2: teamB,
      date: date,
      location: location,
      time: time,
      type: type,
    };

    axios({
      url: "https://lunarsenterprises.com:3007/cricket/edit/match",
      method: "post",
      data: updatedMatch,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.data.result === true) {
          navigate("/matches");
          toast.success(response.data.data);
          console.log("Match updated successfully");
          setLoader(false);
        } else {
          console.log("Failed to update match");
          setLoader(false);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.error("Error fetching teams:", err);
      });
  };
  function formatDate(inputDate) {
    const date = new Date(inputDate);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }
  return (
    <div className="match-form-container">
      <ToastContainer autoClose={3000} theme={"light"} />
      <h2>Edit Match</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Team A:</label>
          <select
            value={teamA}
            onChange={(e) => setTeamA(e.target.value)}
            required
          >
            <option value="">Select Team A</option>
            {options
              .filter((team) => team.t_name !== teamB)
              .map((team) => (
                <option key={team.t_name} value={team.t_name}>
                  {team.t_name}
                </option>
              ))}
          </select>
        </div>
        <div>
          <label>Team B:</label>
          <select
            value={teamB}
            onChange={(e) => setTeamB(e.target.value)}
            required
          >
            <option value="">Select Team B</option>
            {options
              .filter((team) => team.t_name !== teamA)
              .map((team) => (
                <option key={team.t_name} value={team.t_name}>
                  {team.t_name}
                </option>
              ))}
          </select>
        </div>
        <div>
          <label>Date:</label>

          <input
            type="date"
            // placeholder={date}
            value={formatDate(date)}
            onChange={(e) => setDate(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Time:</label>
          <input
            type="time"
            value={time}
            onChange={(e) => setTime(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Location:</label>
          <input
            type="text"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Type:</label>
          <input
            type="text"
            value={type}
            onChange={(e) => setType(e.target.value)}
            required
          />
        </div>

        <div className="full-width">
          <button type="submit">
            {loader ? (
              <ThreeDots
                visible={true}
                height="40"
                width="40"
                color="#4fa94d"
                radius="9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            ) : (
              "Update Match"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditMatch;
