import React, { useEffect, useState } from "react";
import { Table, Container, Button } from "react-bootstrap";
import axios from "axios";
import { FaTrash } from "react-icons/fa";
import "../styles/EnquiryList.css";

const EnquiryList = () => {
  const [enquiries, setEnquiries] = useState([]);

  useEffect(() => {
    fetchEnquiries();
  }, []);

  const fetchEnquiries = async () => {
    try {
      axios({
        url: "https://lunarsenterprises.com:3007/cricket/list/form",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((data) => {
          setEnquiries(data.data.list);
        })
        .catch((err) => {
          console.log(err, "error");
        });
    } catch (error) {
      console.error("Error fetching enquiries:", error);
    }
  };

  const handleDelete = (id) => {
    axios({
      url: "https://lunarsenterprises.com:6013/kdpetroleum/delete",
      method: "POST",
      data: { c_id: id },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(() => {
        fetchEnquiries();
      })
      .catch((error) => {
        console.error("Error deleting enquiry:", error);
      });
  };

  return (
    <Container className="enquiryList__container">
      <h2 className="enquiryList__heading">Enquiry List</h2>
      <Table striped bordered hover responsive className="enquiryList__table">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Mobile Number</th>
            <th>Email</th>
            <th>Subject</th>
            <th>Message</th>
            <th>Date</th>
            {/* <th>Actions</th>   */}
          </tr>
        </thead>
        <tbody>
          {enquiries?.map((enquiry, index) => (
            <tr key={index}>
              <td data-label="#"> {index + 1} </td>
              <td data-label="Name"> {enquiry.f_name} </td>
              <td data-label="MobileNumber">{enquiry.f_phn_no}</td>
              <td data-label="Email"> {enquiry.f_email} </td>
              <td data-label="Subject"> {enquiry.f_subject}</td>
              <td data-label="Message"> {enquiry.f_description} </td>
              <td data-label="Date">
                {new Date(enquiry.date).toLocaleDateString()}
              </td>
              {/* <td data-label="Actions">
                <Button
                  variant="danger"
                  onClick={() => handleDelete(enquiry.c_id)}
                  className="enquiryList__delete-btn"
                >
                  <FaTrash />
                </Button>
              </td> */}
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default EnquiryList;
