import React, { useEffect, useState } from "react";
import "../styles/PlayerCard.css";
import { Link } from "react-router-dom";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmationModel from "../components/ConfirmationModel";

const GalleryCard = ({ media, onDelete }) => {
  const baseurl = "https://lunarsenterprises.com:3007/";

  const isVideo = media?.g_image?.endsWith(".mp4");

  return (
    <div className="player-card">
      {isVideo ? (
        <>
          <video
            src={baseurl + media?.g_image}
            loop
            autoPlay
            playsInline
            muted
            className="player-avatar"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.poster = baseurl + "path/to/default-image.jpg";
            }}
          />
        </>
      ) : (
        <img
          src={baseurl + media?.g_image}
          alt={media?.g_i_name}
          className="player-avatar"
        />
      )}
      <button
        className="delete-button"
        onClick={() => onDelete(media.g_id)}
        title="Delete"
      >
        <i className="fa fa-trash" aria-hidden="true">
          <DeleteIcon />
        </i>
      </button>
    </div>
  );
};

const GalleryPage = () => {
  const [gallery, setGallery] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedGalleryId, setSelectedGalleryId] = useState(null);

  useEffect(() => {
    fetchGallery();
  }, []);

  const fetchGallery = () => {
    axios({
      url: "https://lunarsenterprises.com:3007/cricket/gallery/list",
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((data) => {
        if (data.data.result === true) {
          setGallery(data.data.list);
          console.log("Fetched gallery successfully");
        } else {
          console.log("Failed to fetch gallery");
        }
      })
      .catch((err) => {
        console.error("Error fetching gallery:", err);
      });
  };

  const handleDelete = (response) => {
    if (response.isConfirm) {
      axios({
        url: "https://lunarsenterprises.com:3007/cricket/remove/gallery",
        data: {
          user_id: localStorage.getItem("USER_ID"),
          g_id: selectedGalleryId,
        },
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.data.result === true) {
            fetchGallery();
            console.log("Deleted successfully");
          } else {
            console.log("Delete operation failed");
          }
        })
        .catch((err) => {
          console.error("Error deleting the item:", err);
        });
    }
    setShowConfirmModal(false);
  };
  const onDeleteConfirm = (playerId) => {
    console.log(playerId, "selectedPlayerId");

    setSelectedGalleryId(playerId);
    setShowConfirmModal(true);
  };
  return (
    <div>
      <div className="button-container">
        <h1>Gallery</h1>
        <Link to="/Gallery/create">
          <button className="create-button">Create</button>
        </Link>
      </div>
      <div className="player-list">
        {gallery.map((media, index) => (
          <GalleryCard key={index} media={media} onDelete={onDeleteConfirm} />
        ))}
      </div>
      {showConfirmModal && (
        <ConfirmationModel
          id={selectedGalleryId}
          callback={handleDelete}
          onClose={() => setShowConfirmModal(false)}
        />
      )}
    </div>
  );
};

export default GalleryPage;
