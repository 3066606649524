import React, { useState } from "react";
import "../styles/AttendancePage.css";

function AttendancePage() {
  // Batch structure with student objects for better state management
  const batches = {
    Morning: [
      { name: "John Doe", profile: "/upload/" },
      { name: "Jane Smith", profile: "/upload/" },
      { name: "Emily Johnson", profile: "/upload/" },
    ],
    Evening: [
      { name: "Michael Brown", profile: "/upload/" },
      { name: "Sarah Davis", profile: "/upload/" },
      { name: "Paul Walker", profile: "/upload/" },
    ],
    Holiday: [
      { name: "Anna Taylor", profile: "/upload/" },
      { name: "Chris Martin", profile: "/upload/" },
      { name: "James Wilson", profile: "/upload/" },
    ],
    Regular: [
      { name: "Anna Taylor", profile: "/upload/" },
      { name: "Chris Martin", profile: "/upload/" },
      { name: "James Wilson", profile: "/upload/" },
    ],
  };

  const [activeBatch, setActiveBatch] = useState("Morning");
  const [filterDate, setFilterDate] = useState("");
  const [attendance, setAttendance] = useState(
    Object.fromEntries(
      Object.values(batches)
        .flat()
        .map((student) => [student.name, { status: "neutral", late: false }])
    )
  );

  const toggleAttendance = (student, status) => {
    setAttendance((prev) => ({
      ...prev,
      [student]: {
        ...prev[student],
        status: prev[student].status === status ? "neutral" : status,
      },
    }));
  };

  const toggleLate = (student) => {
    setAttendance((prev) => ({
      ...prev,
      [student]: {
        ...prev[student],
        late: !prev[student].late,
      },
    }));
  };

  const handleDateChange = (e) => {
    setFilterDate(e.target.value);
  };

  const downloadPDF = () => {
    console.log("Downloading PDF...");
  };

  const downloadExcel = () => {
    console.log("Downloading Excel...");
  };

  return (
    <div className="attendance-page">
      {/* Filter and Export Section */}
      <div className="attendance-filter-section">
        <h2>Attendance Management</h2>
        <div className="attendance-filter-container">
          <label htmlFor="dateFilter">Filter by Date:</label>
          <input
            type="date"
            id="dateFilter"
            value={filterDate}
            onChange={handleDateChange}
          />
          <div className="filter-export-container">
            <button
              className="attendance-download-button"
              onClick={downloadPDF}
            >
              Download PDF
            </button>
            <button
              className="attendance-download-button"
              onClick={downloadExcel}
            >
              Download Excel
            </button>
          </div>
        </div>
      </div>

      {/* Batch Tabs */}
      <div className="attendance-batch-tabs">
        {Object.keys(batches).map((batch) => (
          <button
            key={batch}
            className={`attendance-tab-button ${
              activeBatch === batch ? "active" : ""
            }`}
            onClick={() => setActiveBatch(batch)}
          >
            {batch} Batch
          </button>
        ))}
      </div>

      {/* Student List */}
      <ul className="attendance-student-list">
        {batches[activeBatch].map((student, index) => (
          <li key={index} className="attendance-student-item">
            <div className="student-info">
              <span>{index + 1}.</span>
              <img
                src={student.profile}
                alt={`${student.name}'s profile`}
                className="student-profile"
              />
              <span>{student.name}</span>
            </div>
            <div className="attendance-controls">
              <div
                className={`attendance-attendance-slider ${
                  attendance[student.name].status
                }`}
              >
                <span
                  className="attendance-slider-label attendance-absent-label"
                  onClick={() => toggleAttendance(student.name, "absent")}
                >
                  Absent
                </span>
                <span
                  className="attendance-slider-label attendance-present-label"
                  onClick={() => toggleAttendance(student.name, "present")}
                >
                  Present
                </span>
                <div className="attendance-slider-button"></div>
              </div>
              <label className="attendance-late-checkbox">
                <input
                  type="checkbox"
                  checked={attendance[student.name].late}
                  onChange={() => toggleLate(student.name)}
                />
                Late
              </label>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default AttendancePage;
