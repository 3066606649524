import React, { useState, useEffect } from "react";
import "../styles/AddAuction.css";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const EditAuction = () => {
  const navigator = useNavigate();
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const id = pathSegments[pathSegments.length - 1];

  const [selectedImage, setSelectedImage] = useState(null);
  const [currentImage, setCurrentImage] = useState(null);
  const [formData, setFormData] = useState({
    au_name: "",
    au_date: "",
    au_time: "",
    au_points_per_team: "",
    au_base_bid: "",
    au_bid_increase_by: "",
    au_max_players: "",
    au_min_players: "",
    au_season: "",
    au_tour_id: "",
    au_tour_name: "",
  });
  const [tournament, setTournament] = useState([]);
  const baseurl = "https://lunarsenterprises.com:3007/";
  useEffect(() => {
    function formatDate(inputDate) {
      const date = new Date(inputDate);

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");

      return `${day}-${month}-${year}`;
    }
    axios({
      url: "https://lunarsenterprises.com:3007/cricket/view/auc",
      headers: {
        Accept: "application/json",
      },
      method: "POST",
      data: { auc_id: id },
    })
      .then((response) => {
        if (response.data.result) {
          const data = response?.data?.list[0];
          console.log(response.data, "response.data");

          setFormData({
            au_name: data.au_name,
            au_date: formatDate(data.au_date),
            au_time: data.au_time,
            au_points_per_team: data.au_points_per_team,
            au_base_bid: data.au_base_bid,
            au_bid_increase_by: data.au_bid_increase_by,
            au_max_players: data.au_max_players,
            au_min_players: data.au_min_players,
            au_season: data.au_season,
            au_tour_id: data.au_tour_id,
            au_tour_name: data?.tr_name,
          });
          setCurrentImage(data.au_image);
        } else {
          console.log("not working");
        }
      })
      .catch((error) => {
        console.error("Error fetching auction details:", error);
        toast.error("Failed to load auction details.");
      });
  }, [id]);
  useEffect(() => {
    TournamentList();
  }, []);
  const TournamentList = () => {
    axios
      .post("https://lunarsenterprises.com:3007/cricket/list/tour")
      .then((data) => {
        if (data.data.result === true) {
          setTournament(data.data.list);
        } else {
          console.error("Failed to fetch tournaments");
        }
      })
      .catch((err) => {
        console.error("Error fetching tournaments:", err);
      });
  };
  const handleInputChange = (event) => {
    console.log(event, "event");

    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      setCurrentImage(URL.createObjectURL(file));
    }
  };

  const handleEditAuction = (event) => {
    event.preventDefault();

    const payload = new FormData();
    payload.append("au_name", formData.au_name);
    payload.append("au_date", formData.au_date);
    payload.append("au_time", formData.au_time);
    payload.append("au_points_per_team", formData.au_points_per_team);
    payload.append("au_base_bid", formData.au_base_bid);
    payload.append("au_bid_increase_by", formData.au_bid_increase_by);
    payload.append("au_max_players", formData.au_max_players);
    payload.append("au_min_players", formData.au_min_players);
    payload.append("au_season", formData.au_season);
    payload.append("au_tour_id", formData.au_tour_id);

    if (selectedImage) {
      payload.append("image", selectedImage);
    }

    axios({
      url: "https://lunarsenterprises.com:3007/cricket/edit/auc",
      data: payload,
      headers: {
        Accept: "application/json",
      },
      method: "POST",
    })
      .then((response) => {
        toast.success(response.data.message || "Auction updated successfully!");
        navigator("/Auction");
      })
      .catch((error) => {
        console.error("Error updating auction:", error);
        toast.error("Failed to update auction. Please try again.");
      });
  };

  return (
    <div className="add-auction-body">
      <div className="add-auction-container">
        <h1 className="add-auction-title">EDIT AUCTION</h1>
        <form className="add-auction-form" onSubmit={handleEditAuction}>
          <label htmlFor="auction-logo">Auction Logo</label>
          <div className="auction-team-add__image-upload">
            {currentImage ? (
              <img
                src={baseurl + currentImage}
                alt="Auction Logo"
                className="auction-team-add__image-preview"
              />
            ) : (
              <div className="auction-team-add__image-placeholder">
                No image uploaded
              </div>
            )}
            <input
              type="file"
              accept="image/*"
              className="auction-team-add__image-input"
              onChange={handleImageChange}
            />
          </div>
          <div className="add-auction-form-row">
            <div className="add-auction-form-group">
              <label htmlFor="au_season">Season</label>
              <select
                id="au_season"
                name="au_season"
                className="add-auction-select"
                value={formData.au_season}
                onChange={handleInputChange}
              >
                <option value="">--select--</option>
                {[...Array(10).keys()].map((num) => (
                  <option key={num + 1} value={num + 1}>
                    {num + 1}
                  </option>
                ))}
              </select>
            </div>
            <div className="add-auction-form-group">
              <label htmlFor="au_tour_id">Tournament</label>
              <select
                id="au_tour_id"
                name="au_tour_id"
                className="add-auction-select"
                value={formData.au_tour_id}
                onChange={handleInputChange}
              >
                <option value="">--select--</option>
                {tournament.map((item) => (
                  <option key={item.tr_id} value={item.tr_id}>
                    {item.tr_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="add-auction-form-group">
            <label htmlFor="au_name">Auction Name*</label>
            <input
              type="text"
              id="au_name"
              name="au_name"
              className="add-auction-input"
              placeholder="Auction Name"
              value={formData.au_name}
              onChange={handleInputChange}
            />
          </div>

          {[
            { id: "au_date", label: "Auction Date*", type: "date" },
            { id: "au_time", label: "Auction Time", type: "time" },
            {
              id: "au_points_per_team",
              label: "Points Per Team*",
              type: "number",
            },
            { id: "au_base_bid", label: "Base Bid*", type: "number" },
            {
              id: "au_bid_increase_by",
              label: "Bid Increase by*",
              type: "number",
            },
            {
              id: "au_max_players",
              label: "Player Per Team (Max Limit)*",
              type: "number",
            },
            {
              id: "au_min_players",
              label: "Player Per Team (Min Limit)*",
              type: "number",
            },
          ].map((field) => (
            <div className="add-auction-form-group" key={field.id}>
              <label htmlFor={field.id}>{field.label}</label>
              <input
                type={field.type}
                id={field.id}
                name={field.id}
                className="add-auction-input"
                placeholder={field.label}
                value={formData[field.id]}
                onChange={handleInputChange}
              />
            </div>
          ))}

          <button type="submit" className="add-auction-submit-btn">
            SAVE CHANGES
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditAuction;
