import React from "react";
import "../styles/Modal.css"; // Separate CSS file

const Modal = ({ isOpen, onClose, teamDetails }) => {
  if (!isOpen) return null;
  console.log(teamDetails, "teamDetails");

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-btn" onClick={onClose}>
          ×
        </button>
        <h2> Player Details-{teamDetails[0]?.t_name} -</h2>
        <h2>Team Balance Point -{teamDetails[0]?.t_bidvalue}</h2>
        <table className="modal-table">
          <thead>
            <tr>
              <th>SL. NO.</th>
              <th>Player Reg No.</th>
              <th>Player Name</th>
              <th>Contact No.</th>
              <th>Sold Points</th>
              {/* <th>Balance Points</th> */}
              <th>Max Bid Point</th>
            </tr>
          </thead>
          <tbody>
            {teamDetails?.map((player, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{player.i_id}</td>
                <td>{player.i_name}</td>
                <td>{player.i_mobile}</td>
                <td>{player.i_sale_bid}</td>
                {/* <td>{player.i_min_bidvalue}</td> */}
                <td>{player.i_max_bidvalue}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Modal;
