import React, { useEffect, useState } from "react";
import "../styles/PlayerCard.css";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import ConfirmationModel from "../components/ConfirmationModel";

const TeamCard = ({ player, playlist, onDelete }) => {
  const navigate = useNavigate();
  const baseurl = "https://lunarsenterprises.com:3007/";

  return (
    <div className="player-card">
      <DeleteIcon
        onClick={() => onDelete(player.t_id)}
        className="delete-icon"
      />
      <h2>{player.t_name}</h2>
      <div className="player-avatar-container">
        <img
          src={baseurl + player.t_logo}
          alt={player.t_name}
          className="player-avatar"
        />
      </div>
      <p>Member Count</p>
      <p>{player.t_act_count}</p>
    </div>
  );
};
const TeamsPage = () => {
  const [players, setPlayers] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedTeamId, setSelectedTeamId] = useState(null);
  useEffect(() => {
    PlayerList();
  }, []);
  const PlayerList = () => {
    axios({
      url: "https://lunarsenterprises.com:3007/cricket/team/list",
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((data) => {
        if (data.data.result === true) {
          setPlayers(data.data.list);
        } else {
          console.log("not working");
        }
      })
      .catch((err) => {
        console.error("Error fetching teams:", err);
      });
  };
  const handleDelete = (response) => {
    if (response.isConfirm) {
      axios
        .post("https://lunarsenterprises.com:3007/cricket/team/delete", {
          t_id: selectedTeamId,
        })
        .then((response) => {
          if (response.data.result) {
            console.log("Team deleted:", response);
            PlayerList();
            // window.location.reload();
          } else {
            alert(response.data.message);
          }
        })
        .catch((err) => console.error("Error deleting team:", err));
    }
    setShowConfirmModal(false);
  };
  const onDeleteConfirm = (playerId) => {
    console.log(playerId, "selectedPlayerId");

    // Show confirmation modal and set the selected player ID
    setSelectedTeamId(playerId);
    setShowConfirmModal(true);
  };
  return (
    <div>
      <div className="button-container">
        <h1>Team</h1>
      </div>
      <div className="player-list">
        {players.map((player, index) => (
          <TeamCard
            key={index}
            player={player}
            playlist={PlayerList}
            onDelete={onDeleteConfirm}
          />
        ))}
      </div>
      {showConfirmModal && (
        <ConfirmationModel
          id={selectedTeamId}
          callback={handleDelete}
          onClose={() => setShowConfirmModal(false)}
        />
      )}
    </div>
  );
};

export default TeamsPage;
