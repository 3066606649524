import React, { useEffect, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "../styles/AuctionPage.css";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import ConfirmationModel from "../components/ConfirmationModel";
import { useNavigate } from "react-router-dom";
import { CategoryOutlined, Group, Info, Person } from "@mui/icons-material";
import Breadcrumbs from "../components/Breadcrumbs";
import "react-tooltip/dist/react-tooltip.css";
import defaultImg from "../assets/images/EliteCricketAcademyLogo.png";

const AutionCard = ({ Auction, onDelete }) => {
  const navigation = useNavigate();
  const baseurl = "https://lunarsenterprises.com:3007/";
  function formatDate(inputDate) {
    const date = new Date(inputDate);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${day}-${month}-${year}`;
  }
  return (
    <div className="team-card">
      <div className="team-card-header">
        <span className="team-code">A Code: {Auction.au_id}</span>
        {/* <span className="status-tag">DEACTIVE</span> */}
      </div>
      <div
        className="team-card-body"
        onClick={() => navigation(`/MainAuctionPage/${Auction?.au_id}`)}
      >
        {Auction.au_image ? (
          <img
            src={baseurl + Auction.au_image}
            // alt={Auction.au_image}
            className="team-logo"
          />
        ) : (
          <img
            src={defaultImg}
            // alt={Auction.au_image}
            className="team-logo"
          />
        )}
        <h2 className="team-name">{Auction.au_name}</h2>
        <p className="team-date">
          <i className="calendar-icon"></i>{" "}
          {formatDate(Auction.au_date) || "N/A"}
        </p>
        <div className="team-stats">
          <div>
            <p>
              <strong>Point:</strong> {Auction.au_points_per_team || "0"}
            </p>
            <p>
              <strong>Bid Incr:</strong> {Auction.au_bid_increase_by || "0"}
            </p>
          </div>
          <div>
            <p>
              <strong>Base Bid:</strong> {Auction.au_base_bid || "0"}
            </p>
            <p>
              <strong>Members:</strong> {Auction.au_max_players || "N/A"}/Team
            </p>
          </div>
        </div>
      </div>

      <div className="team-card-footer">
        <ReactTooltip />
        <div
          data-tooltip-content={"Team"}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Group
            className="action-icon"
            onClick={() => navigation(`/AuctionTeam/${Auction.au_id}`)}
          />
          Team
        </div>

        {/* <CategoryOutlined
          className="action-icon"
          onClick={() => navigation("/AuctionCategory")}
        /> */}
        <div
          data-tooltip-float={"Players"}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Person
            className="action-icon"
            onClick={() => navigation(`/AuctionPlayer/${Auction.au_tour_id}`)}
          />
          players
        </div>
        <div
          data-tooltip-content="view"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Info
            className="action-icon"
            onClick={() => navigation(`/AuctionViewing/${Auction.au_id}`)}
          />
          View
        </div>

        <EditIcon
          className="action-icon"
          onClick={() => navigation(`/EditAuction/${Auction.au_id}`)}
        />
        <DeleteIcon
          className="action-icon"
          onClick={() => onDelete(Auction.au_id)}
        />
      </div>
    </div>
  );
};

const AuctionPage = () => {
  const [auction, setAuction] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedTeamId, setSelectedTeamId] = useState(null);
  const navigation = useNavigate();

  useEffect(() => {
    fetchAuction();
  }, []);

  const fetchAuction = () => {
    axios
      .post("https://lunarsenterprises.com:3007/cricket/list/auction", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((data) => {
        if (data.data.result) {
          setAuction(data.data.list);
        } else {
          console.error("Failed to fetch team list.");
        }
      })
      .catch((err) => {
        console.error("Error fetching teams:", err);
      });
  };

  const handleDelete = (response) => {
    if (response.isConfirm) {
      axios
        .post("https://lunarsenterprises.com:3007/cricket/delete/auc", {
          auc_id: selectedTeamId,
        })
        .then((response) => {
          if (response.data.result) {
            fetchAuction();
          } else {
            alert(response.data.message);
          }
        })
        .catch((err) => console.error("Error deleting team:", err));
    }
    setShowConfirmModal(false);
  };

  const onDeleteConfirm = (teamId) => {
    setSelectedTeamId(teamId);
    setShowConfirmModal(true);
  };

  return (
    <div className="auction-page">
      <Breadcrumbs />
      <div className="auction-header">
        <h1>My Auction</h1>
        <button
          className="add-button"
          onClick={() => navigation("/AddAuction")}
        >
          + Add
        </button>
      </div>
      <div className="team-list">
        {auction?.map((Auction, index) => (
          <AutionCard
            key={index}
            Auction={Auction}
            onDelete={onDeleteConfirm}
          />
        ))}
      </div>
      {showConfirmModal && (
        <ConfirmationModel
          id={selectedTeamId}
          callback={handleDelete}
          onClose={() => setShowConfirmModal(false)}
        />
      )}
    </div>
  );
};

export default AuctionPage;
