import React, { useState } from "react";
import axios from "axios";
import "../styles/MatchForm.css";
import { useNavigate } from "react-router-dom";

const GalleryCreate = ({ history }) => {
  const [image, setImage] = useState(null);
  const navigate = useNavigate();
  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!image) {
      console.log("No image selected");
      return;
    }

    const formData = new FormData();
    formData.append("image", image);
    formData.append("user_id", localStorage.getItem("USER_ID"));

    axios({
      url: "https://lunarsenterprises.com:3007/cricket/gallery/add",
      method: "post",
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        if (response.data.result === true) {
          console.log("Image uploaded successfully");
          navigate("/Gallery");
        } else {
          console.log("Failed to upload image");
        }
      })
      .catch((error) => {
        console.error("Error uploading image:", error);
      });
  };

  return (
    <div className="match-form-container">
      <h2>Upload Image</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Select Image:</label>
          <input type="file" onChange={handleImageChange} accept="image/*" />
        </div>
        <div className="full-width">
          <button type="submit">Upload Image</button>
        </div>
      </form>
    </div>
  );
};

export default GalleryCreate;
