import React from "react";
import "../styles/AuctionCategory.css";
import { Edit, Edit2, Trash } from "react-feather";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../components/Breadcrumbs";

const dummyData = [
  {
    id: 1,
    title: "keeper",
    player: 1,
    increment: 500.0,
    highestBid: 5000.0,
    base: 1000.0,
    reserve: 100.0,
    categoryBid: 2000.0,
  },
  {
    id: 2,
    title: "bowler",
    player: 2,
    increment: 500.0,
    highestBid: 5000.0,
    base: 1000.0,
    reserve: 100.0,
    categoryBid: 2000.0,
  },
];

function AuctionCategory() {
  const Navigate = useNavigate();
  return (
    <div className="auction-container">
      <Breadcrumbs />
      <h1 className="auction-title">CATEGORY</h1>

      <div className="auction-actions">
        <button className="auction-list-btn">☰</button>
        <button
          className="auction-add-btn"
          onClick={() => Navigate("/AddAuctionCategory")}
        >
          + ADD
        </button>
      </div>

      <div className="auction-card-container">
        {dummyData.map((item) => (
          <div className="auction-card">
            <div className="auction-card-header">
              <h2 className="auction-card-title">{item.title}</h2>
            </div>
            <div className="auction-card-body">
              <div className="auction-row">
                <span className="auction-label">PLAYER:</span>
                <span className="auction-value">{item.player}</span>
              </div>
              <div className="auction-row">
                <span className="auction-label">INCR:</span>
                <span className="auction-value">
                  {item.increment.toFixed(2)}
                </span>
              </div>
              <div className="auction-row">
                <span className="auction-label">H-BID:</span>
                <span className="auction-value">
                  {item.highestBid.toFixed(2)}
                </span>
              </div>
              <div className="auction-row">
                <span className="auction-label">BASE:</span>
                <span className="auction-value">{item.base.toFixed(2)}</span>
              </div>
              <div className="auction-row">
                <span className="auction-label">RESV:</span>
                <span className="auction-value">{item.reserve.toFixed(2)}</span>
              </div>
              <div className="auction-row">
                <span className="auction-label">CAT-BID:</span>
                <span className="auction-value">
                  {item.categoryBid.toFixed(2)}
                </span>
              </div>
            </div>
            <div className="auction-card-footer">
              <button className="auction-edit-btn">
                <Edit2 />
              </button>
              <button className="auction-delete-btn">
                <Trash />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AuctionCategory;
