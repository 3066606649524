import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../styles/MatchesPage.css";
import { Edit3, Trash2 } from "react-feather";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MatchesPage = () => {
  const [activeTab, setActiveTab] = useState("upcoming");
  const [matches, setMatches] = useState([]);

  useEffect(() => {
    MatchList();
  }, []);

  const today = new Date().toISOString().split("T")[0];
  const upcomingMatches = matches.filter((match) => match?.m_date >= today);
  const pastMatches = matches.filter((match) => match?.m_date < today);

  const handleDelete = (id) => {
    axios({
      url: "https://lunarsenterprises.com:3007/cricket/delete/match",
      data: {
        m_id: id,
      },
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((data) => {
        if (data.data.result === true) {
          toast.success(data.data?.message);
          MatchList();
        } else {
          console.log("not working");
        }
      })
      .catch((err) => {
        console.error("Error fetching teams:", err);
      });
  };

  const MatchList = () => {
    axios({
      url: "https://lunarsenterprises.com:3007/cricket/list/match",
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((data) => {
        if (data.data.result === true) {
          setMatches(data.data.list);
        } else {
          console.log("not working");
        }
      })
      .catch((err) => {
        console.error("Error fetching teams:", err);
      });
  };
  return (
    <div className="main-containers">
      <ToastContainer autoClose={3000} theme={"light"} />
      <div
        className="button-container"
        // style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h1>Matches</h1>
        <Link to="/matches/create">
          <button
            style={{
              marginTop: "30px",
              padding: "10px 20px",
              backgroundColor: "#4CAF50",
              color: "white",
              border: "none",
              borderRadius: "8px",
              fontSize: "16px",
              cursor: "pointer",
              transition: "background-color 0.3s ease",
            }}
            onMouseOver={(e) => (e.target.style.backgroundColor = "#45a049")}
            onMouseOut={(e) => (e.target.style.backgroundColor = "#4CAF50")}
          >
            Create New Match
          </button>
        </Link>
      </div>
      {/* <p>Manage upcoming and past matches here.</p> */}

      <div className="tab-nav">
        <button
          className={activeTab === "upcoming" ? "active-tab" : ""}
          onClick={() => setActiveTab("upcoming")}
        >
          Upcoming Matches
        </button>
        <button
          className={activeTab === "past" ? "active-tab" : ""}
          onClick={() => setActiveTab("past")}
        >
          Past Matches
        </button>
      </div>

      <div className="matches-container">
        {activeTab === "upcoming" ? (
          upcomingMatches.length === 0 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <p>No upcoming matches available.</p>
            </div>
          ) : (
            upcomingMatches.map((match) => (
              <div className="match-card" key={match.m_id}>
                <h3>
                  {match.m_team1} vs {match.m_team2}
                </h3>
                <p>Date: {new Date(match.m_date).toLocaleDateString()}</p>
                <p>Location: {match.m_location}</p>

                <div className="edit-delete-icons">
                  {" "}
                  {/* Added container for icons */}
                  <Link to={`/edit/${match.m_id}`}>
                    <button className="edit-btn">
                      <i data-feather="edit-3">
                        <Edit3 />
                      </i>
                    </button>
                  </Link>
                  <button
                    className="delete-btn"
                    onClick={() => handleDelete(match.m_id)}
                    data-bs-toggle="modal"
                    data-original-title="test"
                    data-bs-target="#deleteWarning"
                  >
                    <i data-feather="trash-2">
                      <Trash2 />
                    </i>
                  </button>
                </div>
              </div>
            ))
          )
        ) : pastMatches.length === 0 ? (
          <p>No past matches available.</p>
        ) : (
          pastMatches.map((match) => (
            <div className="match-card" key={match.m_id}>
              <h3>
                {match.m_team1} vs {match.m_team2}
              </h3>
              <p>Date: {new Date(match.m_date).toLocaleDateString()}</p>
              <p>Location: {match.m_location}</p>

              <div className="edit-delete-icons">
                {" "}
                {/* Added container for icons */}
                <Link to={`/edit-match/${match.m_id}`}>
                  <button className="edit-btn">
                    <i data-feather="edit-3">
                      <Edit3 />
                    </i>
                  </button>
                </Link>
                <button
                  className="delete-btn"
                  onClick={() => handleDelete(match.m_id)}
                  data-bs-toggle="modal"
                  data-original-title="test"
                  data-bs-target="#deleteWarning"
                >
                  <i data-feather="trash-2">
                    <Trash2 />
                  </i>
                </button>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default MatchesPage;
