import React, { useEffect, useState } from "react";
import "../styles/SpinPage.css";
import { Link } from "react-router-dom";
import axios from "axios";
import ConfirmationModelTour from "../components/ConfirmationModalTour";

const SpinPage = () => {
  const [spinList, setSpinList] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedSpinId, setSelectedSpinId] = useState(null);

  useEffect(() => {
    SpinList();
  }, []);

  const SpinList = () => {
    axios({
      url: "https://lunarsenterprises.com:3007/cricket/spinwheel/team/list",
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((data) => {
        if (data.data.result === true) {
          setSpinList(data.data.full_list);
        } else {
          console.log("all deleted");
        }
      })
      .catch((err) => {
        console.error("Error fetching teams:", err);
      });
  };

  const handleDelete = (response) => {
    if (response.isConfirm) {
      axios({
        url: "https://lunarsenterprises.com:3007/cricket/spinwheel/team/delete",
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          s_id: selectedSpinId,
        },
      })
        .then((response) => {
          if (response.data.result === true) {
            SpinList();
          } else {
            window.location.reload();
            // alert(response.data.message);
          }
        })
        .catch((err) => {
          console.error("Error deleting spin:", err);
        });
    }
    setShowConfirmModal(false);
  };

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const onDeleteConfirm = (playerId) => {
    console.log(playerId, "selectedPlayerId");

    // Show confirmation modal and set the selected player ID
    setSelectedSpinId(playerId);
    setShowConfirmModal(true);
  };
  return (
    <div className="spin-page">
      <div className="header-container">
        <h1 className="left-align">Spin</h1>

        <Link to="/Spin/create" className="right-align">
          <button className="create-button">Create Spin Details</button>
        </Link>
      </div>

      <div className="spin-list-container">
        {spinList?.length > 0 ? (
          spinList.map((spin, index) => (
            <div key={index} className="spin-item">
              <h3 className="team-name">{spin.s_team_name}</h3>
              <p className="spin-details">Date: {formatDate(spin.s_date)}</p>
              <p className="spin-details">Time: {spin.s_time}</p>
              <p className="spin-details">Member count: {spin.member_count}</p>

              <button
                className="delete-button"
                onClick={() => onDeleteConfirm(spin.s_id)}
              >
                🗑️
              </button>
            </div>
          ))
        ) : (
          <p>No spin details available.</p>
        )}
      </div>
      {showConfirmModal && (
        <ConfirmationModelTour
          id={selectedSpinId}
          callback={handleDelete}
          onClose={() => setShowConfirmModal(false)}
        />
      )}
    </div>
  );
};

export default SpinPage;
