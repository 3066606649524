import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "../styles/TournamentEdit.css";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import "react-toastify/dist/ReactToastify.css";

const TournamentEdit = () => {
  const locations = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState(locations.state.tr_name);
  const [endDate, setEnddate] = useState(locations.state.tr_end_date);
  const [date, setDate] = useState(locations.state.tr_start_date);
  const [location, setLocation] = useState(locations.state.tr_place);
  const [type, setType] = useState(locations.state.tr_type);
  const [options, setOptions] = useState([]);
  const [loader, setLoader] = useState(false);
  const [file, setFile] = useState(locations.state?.tr_image);
  const [newFile, setNewFile] = useState(null);

  useEffect(() => {
    TeamList();
  }, [id]);

  const TeamList = () => {
    axios({
      url: "https://lunarsenterprises.com:3007/cricket/team/list",
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.data.result === true) {
          setOptions(response.data.list);
        } else {
          console.log("Failed to fetch teams");
        }
      })
      .catch((err) => {
        console.error("Error fetching teams:", err);
      });
  };

  const handleSubmit = (e) => {
    setLoader(true);
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("tour_id", parseInt(id));
    formdata.append("tour_name", name);
    formdata.append("start_date", date);
    formdata.append("end_date", endDate);
    formdata.append("place", location);
    formdata.append("tour_type", type);
    formdata.append("image", file ? file : newFile);
    axios({
      url: "https://lunarsenterprises.com:3007/cricket/edit/tournament",
      method: "post",
      data: formdata,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        if (response.data.result === true) {
          navigate("/Tournament");
          toast.success(response.data.data);
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.error("Error fetching teams:", err);
      });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setNewFile(file);
  };

  return (
    <div className="tournament-edit-form-container">
      <ToastContainer autoClose={3000} theme={"light"} />
      <h2>Edit Tournament</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Tournament Name</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div>
          <label>Start Date:</label>
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>
        <div>
          <label>End Date:</label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEnddate(e.target.value)}
          />
        </div>
        <div>
          <label>Location:</label>
          <input
            type="text"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
        </div>
        <div>
          <label>Type:</label>
          <input
            type="text"
            value={type}
            onChange={(e) => setType(e.target.value)}
          />
        </div>
        <div>
          <label>Tournament Banner:</label>
          <input type="file" accept="image/*" onChange={handleImageChange} />
          <div style={{ fontSize: "12px", color: "red" }}>*{file}</div>
        </div>
        <div className="full-width">
          <button type="submit">
            {loader ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <ThreeDots
                  visible={true}
                  height="40"
                  width="40"
                  color="white"
                  radius="9"
                  ariaLabel="three-dots-loading"
                />
              </div>
            ) : (
              "Update Match"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default TournamentEdit;
