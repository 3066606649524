import React, { useEffect, useState } from "react";
import "../styles/MainAuctionPage.css";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { toast } from "react-toastify";
import threedots from "../assets/icons/info.png";
import Modal from "../components/Modal";

const MainAuctionPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathSegments = location.pathname.split("/");
  const id = pathSegments[pathSegments.length - 1];
  const [playerDetails, setPlayerDetails] = useState(null);
  const [bidAmount, setBidAmount] = useState(1000);
  const [auction, setAuction] = useState([]);
  const [team, setTeam] = useState([]);
  const [teamnId, setTeamId] = useState("");
  const [details, setDetails] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState([]);

  useEffect(() => {
    MainAuction();
    TeamList();
  }, []);

  const playerAuctionList = () => {
    axios({
      url: "https://lunarsenterprises.com:3007/cricket/bidding/list-players",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((data) => {
        setPlayerDetails(data.data.list);
      })
      .catch((err) => {
        console.log(err, "errror");
      });
  };

  const handleNewPlayerClick = () => {
    playerAuctionList();
    setBidAmount(1000);
  };

  const handleBidChange = (e) => {
    setBidAmount(parseInt(e.target.value) || 0);
  };

  const handleTeamClick = (increment, team_id, team) => {
    if (playerDetails) {
      // Remaining slots and budget
      const remainingSlots = team.t_member_count - team.t_act_count;
      const remainingTotalBudget = team.t_bidvalue - (bidAmount + increment);

      // New dynamic average budget per player
      const newAverageBudgetPerPlayer =
        remainingSlots > 0 ? remainingTotalBudget / remainingSlots : 0;

      // Minimum budget per player (dynamic threshold, can be adjusted)
      const minimumPerPlayerBudget = 1000; // Define as per your system logic

      // Validation: Ensure slots are available
      if (remainingSlots <= 0) {
        toast.error(
          `Team ${team.t_name} has already reached the maximum player count!`
        );
        return;
      }

      // Validation: Ensure total budget is sufficient
      if (remainingTotalBudget < 0) {
        toast.error(
          `Team ${team.t_name} cannot afford this bid. Remaining total budget: ${team.t_bidvalue}.`
        );
        return;
      }

      // Validation: Ensure enough budget for remaining players
      if (newAverageBudgetPerPlayer < minimumPerPlayerBudget) {
        toast.error(`Bit Value Limit Reached`);
        return;
      }

      // If validations pass, update bid and team details
      setBidAmount((prevBid) => prevBid + increment); // Update current bid
      setTeamId(team_id); // Track which team is bidding
      setDetails(team); // Update team details

      console.log(
        `Bid successful! New bid amount: ${bidAmount + increment}, 
        Remaining total budget: ${remainingTotalBudget}, 
        Average budget per remaining player: ${newAverageBudgetPerPlayer.toFixed(
          2
        )}`
      );
    }
  };

  const handleStatusChange = (status) => {
    setPlayerDetails((prev) => ({
      ...prev,
      status: status,
    }));
  };

  const MainAuction = () => {
    axios({
      url: "https://lunarsenterprises.com:3007/cricket/view/auc",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        auc_id: id,
      },
    })
      .then((data) => {
        if (data.data.result) {
          setAuction(data.data.list);
        } else {
          console.log("not working");
        }
      })
      .catch((err) => {
        console.log(err, "errorin AuctionView");
      });
  };
  const TeamList = () => {
    axios({
      url: "https://lunarsenterprises.com:3007/cricket/list-auc/team",
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        auc_id: JSON.parse(id),
      },
    })
      .then((data) => {
        if (data.data.result === true) {
          setTeam(data.data.list);
        } else {
          console.log("not working");
        }
      })
      .catch((err) => {
        console.error("Error fetching teams:", err);
      });
  };
  const AuctionBidding = () => {
    axios({
      url: "https://lunarsenterprises.com:3007/cricket/bidding/edit-players",
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        i_id: playerDetails?.i_id,
        status: playerDetails.status,
        bid_value: playerDetails.status == "Unsold" ? "" : bidAmount,
        t_id: playerDetails.status == "Unsold" ? "" : teamnId,
        auc_id: id,
      },
    })
      .then((data) => {
        if (data.data.result) {
          setBidAmount(0);
          playerAuctionList();
          TeamList();
          console.log(data, "data");
        } else {
          toast.error(data.data.message);
        }
      })
      .catch((err) => {
        console.log(err, "error ");
      });
  };
  const handleReauctionClick = () => {
    axios({
      url: "https://lunarsenterprises.com:3007/cricket/bidding/list-players",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        auc_id: id,
      },
    })
      .then((data) => {
        setPlayerDetails(data.data.list);
      })
      .catch((err) => {
        console.log(err, "errror");
      });
  };
  const players = (t_id) => {
    setSelectedTeam([]);
    axios({
      url: "https://lunarsenterprises.com:3007/cricket/list-player/team",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      data: {
        t_id: t_id,
      },
    })
      .then((data) => {
        if (data.data.result) {
          setSelectedTeam(data.data.list);
          setIsModalOpen(true);
        } else {
          console.log(data.data.message);
          setIsModalOpen(true);
          toast.error("No players to Display");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleModalOpen = (team) => {
    players(team.t_id);
  };
  const baseurl = "https://lunarsenterprises.com:3007/";
  return (
    <div className="main-auction-page">
      <header className="main-auction-header">
        <div className="main-auction-header-left">
          <h1>Bidding for {auction[0]?.au_name}</h1>
        </div>
      </header>

      <main className="main-auction-content">
        {/* Teams Row */}

        <div className="auction-teams-row">
          {team.map((team) => (
            <div key={team.t_id}>
              <div
                style={{ marginTop: "5px" }}
                onClick={() => handleModalOpen(team)}
              >
                <img
                  src={threedots}
                  style={{ width: "20px", height: "20px" }}
                  alt="Options"
                />
              </div>
              <div
                key={team.t_id}
                className="auction-team-card"
                onClick={() =>
                  handleTeamClick(
                    auction[0]?.au_bid_increase_by,
                    team.t_id,
                    team
                  )
                }
              >
                <div className="team-logo">
                  <img
                    src={baseurl + team.t_logo}
                    className="team-logo-auction"
                  />
                </div>
                <div className="team-names">{team.t_name}</div>
                <div className="team-increment">
                  +{auction[0]?.au_bid_increase_by}
                </div>
              </div>
              <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                teamDetails={selectedTeam}
              />
            </div>
          ))}
        </div>

        {/* Player Card */}
        <div className="Container-main-auctions">
          {playerDetails && (
            <div className="main-auction-player-details">
              <div className="main-auction-player-image-container">
                <div className="main-auction-player-image">
                  <img
                    src={baseurl + playerDetails?.i_profile_pic}
                    className="player-logo-auction"
                  />
                </div>
              </div>

              <div className="main-auction-player-info">
                <h3 className="main-auction-player-name">
                  {playerDetails?.i_name}{" "}
                  {playerDetails?.status && (
                    <span className="player-status">
                      ({playerDetails?.status})
                    </span>
                  )}
                </h3>
                <ul className="main-auction-player-attributes">
                  <li>{playerDetails?.i_role}</li>
                  <li>{playerDetails?.i_batting_style}</li>
                  <li>{playerDetails?.i_bowiling_style}</li>
                </ul>
                <p className="main-auction-player-age">
                  {playerDetails?.i_age} YRS
                </p>

                {/* Bid Section */}
                <div className="bid-section">
                  <input
                    type="number"
                    className="bid-input"
                    placeholder="Enter your bid"
                    value={bidAmount}
                    onChange={handleBidChange}
                  />
                  <button className="bid-btn" onClick={() => AuctionBidding()}>
                    Finish Bid
                  </button>
                </div>

                {/* Status Buttons */}
                {bidAmount > 0 && (
                  <div className="status-buttons">
                    <button
                      className="sold-btn"
                      onClick={() => handleStatusChange("sold")}
                    >
                      SOLD
                    </button>
                    <button
                      className="unsold-btn"
                      onClick={() => handleStatusChange("Unsold")}
                    >
                      UNSOLD
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
          {details ? (
            <div className="auction-ui-container">
              <div className="auction-ui-score">
                <span className="auction-ui-score-number">{bidAmount}</span>
              </div>

              <div className="auction-ui-team-details">
                <div className="auction-ui-team-logo">
                  <img src={baseurl + details?.t_logo} alt="Team Logo" />
                </div>
                <div className="auction-ui-team-name">{details?.t_name}</div>
              </div>

              <div className="auction-ui-team-stats">
                <div className="auction-ui-stat-box">
                  <div className="auction-ui-stat-label">TOTAL POINTS</div>
                  <div className="auction-ui-stat-value">
                    {details?.t_bidvalue}
                  </div>
                </div>
                <div className="auction-ui-stat-box">
                  <div className="auction-ui-stat-label">PLAYERS</div>
                  <div className="auction-ui-stat-value">
                    {details?.t_act_count} /{details?.t_member_count}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </main>

      <footer className="main-auction-footer">
        <div className="main-auction-footer-buttons">
          <span
            className="main-auction-footer-btn main-auction-footer-id"
            onClick={handleReauctionClick}
          >
            Reacution For UnSold players
          </span>
          <span
            className="main-auction-footer-btn main-auction-footer-btn-new-player"
            onClick={handleNewPlayerClick}
          >
            NEW PLAYER
          </span>
          <span className="main-auction-footer-btn main-auction-footer-id">
            {playerDetails?.i_id}
          </span>

          <span className="main-auction-footer-btn main-auction-footer-bid">
            {bidAmount}
          </span>
          <span
            className="main-auction-footer-btn main-auction-footer-id"
            onClick={() => navigate(`/AuctionPlayer/${auction[0].au_tour_id}`)}
          >
            players List
          </span>
        </div>
      </footer>
    </div>
  );
};

export default MainAuctionPage;
