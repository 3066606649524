import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../styles/subdomain.css";

const AddSubdomain = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const isEditing = !!id;

  const [formData, setFormData] = useState({
    name: "",
    role: "",
    email: "",
    password: "",
  });

  useEffect(() => {
    if (isEditing) {
      // Simulate fetching user data (replace with API calls)
      const existingUser = {
        id: 1,
        name: "John Doe",
        role: "Admin",
        email: "john@example.com",
        password: "12345",
      };
      setFormData(existingUser);
    }
  }, [isEditing]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !formData.name ||
      !formData.role ||
      !formData.email ||
      !formData.password
    ) {
      alert("All fields are required.");
      return;
    }
    if (isEditing) {
      alert(`User "${formData.name}" updated successfully!`);
    } else {
      alert(`User "${formData.name}" added successfully!`);
    }
    navigate("/");
  };

  return (
    <div>
      <h1 className="subdomain-h1">{isEditing ? "Edit User" : "Add User"}</h1>
      <form className="subdomain-form" onSubmit={handleSubmit}>
        <div className="subdomain-form-group">
          <label>Name</label>
          <input
            className="form"
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </div>
        <div className="subdomain-form-group">
          <label>Role</label>
          <input
            className="form"
            type="text"
            name="role"
            value={formData.role}
            onChange={handleChange}
          />
        </div>
        <div className="subdomain-form-group">
          <label>Email</label>
          <input
            className="form"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className="subdomain-form-group">
          <label>Password</label>
          <input
            className="form"
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
          />
        </div>
        <button type="submit" className="subdomain-submit-btn">
          {isEditing ? "Update User" : "Add User"}
        </button>
      </form>
    </div>
  );
};

export default AddSubdomain;
