import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/MatchForm.css";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const MatchForm = () => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [teamA, setTeamA] = useState("");
  const [teamB, setTeamB] = useState("");
  const [date, setDate] = useState("");
  const [location, setLocation] = useState("");
  const [time, setTime] = useState("");
  const [type, setType] = useState("");
  const [options, setOptions] = useState([]);

  useEffect(() => {
    TeamList();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Fetch team list API
  const TeamList = () => {
    axios({
      url: "https://lunarsenterprises.com:3007/cricket/team/list",
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.data.result === true) {
          setOptions(response.data.list);
          console.log("Fetched teams:", response.data.list);
        } else {
          console.log("Failed to fetch teams");
        }
      })
      .catch((err) => {
        console.error("Error fetching teams:", err);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const match = {
      teamA,
      teamB,
      date,
      location,
      time,
      type,
    };
    console.log(match, "match");

    let requestBody = {
      team1: match.teamA,
      team2: match.teamB,
      location: match.location,
      date: match.date,
      time: match.time,
      type: match.type,
    };

    axios({
      url: "https://lunarsenterprises.com:3007/cricket/match/create",
      method: "post",
      data: requestBody,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((data) => {
        if (data.data.result === true) {
          navigate("/matches");
          console.log("Match created successfully");
          toast.success(data.data.message);
        } else {
          console.log("Failed to create match");
        }
      })
      .catch((err) => {
        console.error("Error fetching teams:", err);
      });
  };

  return (
    <div className="match-form-container">
      <ToastContainer autoClose={3000} theme={"light"} />
      <h2>Create New Match</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Team A:</label>
          <select
            value={teamA}
            onChange={(e) => setTeamA(e.target.value)}
            required
          >
            <option value="">Select Team A</option>
            {options
              .filter((team) => team.t_name !== teamB)
              .map((team) => (
                <option key={team.t_name} value={team.t_name}>
                  {team.t_name}
                </option>
              ))}
          </select>
        </div>
        <div>
          <label>Team B:</label>
          <select
            value={teamB}
            onChange={(e) => setTeamB(e.target.value)}
            required
          >
            <option value="">Select Team B</option>
            {options
              .filter((team) => team.t_name !== teamA)
              .map((team) => (
                <option key={team.t_name} value={team.t_name}>
                  {team.t_name}
                </option>
              ))}
          </select>
        </div>
        <div>
          <label>Date:</label>
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Time:</label>
          <input
            type="time"
            value={time}
            onChange={(e) => setTime(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Location:</label>
          <input
            type="text"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Type:</label>
          <input
            type="text"
            value={type}
            onChange={(e) => setType(e.target.value)}
            required
          />
        </div>

        <div className="full-width">
          <button type="submit">Create Match</button>
        </div>
      </form>
    </div>
  );
};

export default MatchForm;
