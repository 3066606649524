import React, { useEffect, useState } from "react";
import SpinWheels from "./SpinWheels";
import { Modal } from "react-bootstrap";
import { io } from "socket.io-client";
import "../styles/Spin.css";

const SpinWheel = () => {
  const [socket, setSocket] = useState(null);
  const [match, setMatch] = useState(false);
  const [TeamName, setTeamName] = useState("");
  const [spinOut, setSpinOut] = useState("");
  const [start, setStart] = useState(1);
  const [isPlayerModalOpen, setIsPlayerModalOpen] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [spinList, setSpinList] = useState([]);
  const [endSession, setEndSession] = useState("");
  const [tornamentId, setTornamentId] = useState("");

  useEffect(() => {
    const socket = io("https://lunarsenterprises.com:3007/");
    setSocket(socket);
    socket.on("connect", () => {
      socket.emit("spin", console.log("rooms is created"));
    });
    socket.on("userlist", (data) => {
      console.log("Recieved Data", data);
      socket.on("spin_status", (receivedData) => {
        console.log(receivedData, "hoiaa hoi");
        setMatch(receivedData);
      });
      setSpinList(data?.list);
      setTeamName(data?.team_name);
      setEndSession(data?.team_id);
      setTornamentId(data?.tour_id);
    });

    return () => {
      console.log("socket disconnect");
      socket.disconnect();
    };
  }, [start]);
  const socketEmitEvent = () => {
    socket.emit("spinin", console.log("socket emitted spinIn...."));
  };
  const socketEmitOut = (value) => {
    console.log(value, "value");

    let teamData = {
      teamId: endSession,
      sp_id: value[1],
      memberName: value[0],
      tour_id: tornamentId,
      status: "false",
    };
    socket.emit(
      "spinout",
      teamData,
      console.log("socket emitted spinout....", teamData)
    );
    setStart(start + 1);
    // window.location.reload();
  };
  const SocketSectionClose = () => {
    let EndSession = {
      s_id: endSession,
    };
    socket.emit("close_session", EndSession);

    window.location.reload();
  };
  const handlePlayerSelection = (player) => {
    console.log(player, "playersss");

    openPlayerModal(player); // Opens the player details modal
  };

  const openPlayerModal = (player) => {
    console.log(player, "playersss");

    setSelectedPlayer(player);
    setIsPlayerModalOpen(true);
  };
  const closePlayerModal = () => setIsPlayerModalOpen(false);

  return (
    <>
      <div className="modal-contents">
        <div className="modal-header">
          <h2>Spin the Wheel</h2>
        </div>
        <div style={{ margin: "5px", paddingBottom: "10px" }}>
          SELECTION FOR : {TeamName}
        </div>
        <SpinWheels
          onPlayerSelected={handlePlayerSelection}
          spinOutput={setSpinOut}
          spinList={spinList}
          socketEmitEvent={socketEmitEvent}
          socketEmitOut={socketEmitOut}
          match={match}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          SELECTED PLAYER: {match?.memberName ? match?.memberName : spinOut}
        </div>

        <div>
          <button className="end-session-btn" onClick={SocketSectionClose}>
            END SESSION
          </button>
        </div>
      </div>
      {isPlayerModalOpen && (
        <Modal closeModal={closePlayerModal}>
          <div className="player-modal-content">
            <h2>Selected Player</h2>
            <p>
              <strong>Player Name:</strong> {selectedPlayer}
            </p>
            <p>
              <strong> Team:</strong> {TeamName}
            </p>
            <button>SOLD</button>
            <button>UNSOLD</button>
            <button>NEXT</button>
            <button className="player-btn" onClick={closePlayerModal}>
              Close
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default SpinWheel;
