import React, { useState, useEffect } from "react";
import "../styles/SpinWheel.css";
import axios from "axios";

const SpinWheels = ({
  onPlayerSelected,
  spinOutput,
  spinList,
  socketEmitEvent,
  socketEmitOut,
  match,
}) => {
  // console.log(match, "match");

  const [spinner, setSpinner] = useState([]);
  const segments = [
    { color: "#89ABE3" },
    { color: "#EA738D" },

    // { color: "#db7093" },
    // { color: "#20b2aa" },
    // { color: "#d63e92" },
    // { color: "#daa520" },
    // { color: "#ff340f" },
  ];

  const [rotationAngle, setRotationAngle] = useState(0);
  const [curIndex, setCurIndex] = useState(null);

  const rotateWheel = () => {
    socketEmitEvent();
    setRotationAngle(Math.ceil(Math.random() * 3600));
    const randomIndex = Math.floor(Math.random() * spinner.length);
    setCurIndex(randomIndex);
    setTimeout(() => {
      spinOutput(spinner[randomIndex]?.i_name);
      onPlayerSelected(spinner[randomIndex]?.i_name);
      socketEmitOut([
        spinner[randomIndex]?.i_name,
        spinner[randomIndex]?.sp_id,
      ]);
    }, 2500);
  };

  const segmentAngle = 360 / spinner.length;

  useEffect(() => {
    setSpinner(spinList);
  }, [spinList]);

  return (
    <div className="spin_container">
      <div
        className="wheel"
        style={{ transform: `rotate(${rotationAngle}deg)` }}
      >
        {spinner.map((item, index) => (
          <div
            key={index}
            className={`number ${index === curIndex ? "active" : ""}`}
            style={{
              "--i": index + 1,
              "--clr": segments[index % segments.length].color,
              "--segment-angle": `${segmentAngle}deg`,
            }}
          >
            <span>{item.i_name}</span>
          </div>
        ))}
      </div>

      <div className="spinBtn" onClick={rotateWheel}>
        SPIN
      </div>

      <div>{spinOutput}</div>
    </div>
  );
};

export default SpinWheels;
