import React, { useState, useEffect } from "react";
import { Trash2 } from "react-feather";
import { toast } from "react-toastify";
import "../styles/ConfirmModal.css";

function ConfirmationModel({ id, callback, onClose }) {
  const [isVisible, setIsVisible] = useState(true);

  const toastOptions = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const onHandleConfirmation = (isConfirm) => {
    const response = {
      isConfirm,
      id: id || 0,
    };
    toast.success(
      isConfirm ? "Item deleted successfully!" : "Cancelled",
      toastOptions
    );
    setIsVisible(false);
    setTimeout(() => {
      callback(response);
      if (onClose) onClose();
    }, 300);
  };

  return (
    <div
      className={`confirm-modal-overlay ${isVisible ? "fade-in" : "fade-out"}`}
    >
      <div
        className={`confirm-modal-content ${
          isVisible ? "slide-up" : "slide-down"
        }`}
      >
        <div className="confirm-modal-body">
          <div className="confirm-modal-warning-message">
            <Trash2 size={48} color="#ff0000" />
            <h6>Are you sure you want to delete?</h6>
          </div>
        </div>
        <div className="confirm-modal-footer">
          <button
            className="confirm-modal-btn confirm-modal-cancel-btn"
            onClick={() => onHandleConfirmation(false)}
          >
            Cancel
          </button>
          <button
            className="confirm-modal-btn confirm-modal-delete-btn"
            onClick={() => onHandleConfirmation(true)}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmationModel;
