import React, { useEffect, useState } from "react";
import "../styles/AddAuction.css";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AddAuction = () => {
  const Navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);
  const [formData, setFormData] = useState({
    auc_name: "",
    auc_date: "",
    auc_time: "",
    points_per_team: "",
    base_bid: "",
    bid_increase: "",
    max_players: "",
    min_players: "",
    season: "",
    Tornament: "",
  });
  const [tournament, setTournament] = useState([]);

  useEffect(() => {
    TournamentList();
  }, []);

  // Handle text inputs
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle image upload
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
    }
  };
  const TournamentList = () => {
    axios
      .post("https://lunarsenterprises.com:3007/cricket/list/tour")
      .then((data) => {
        if (data.data.result === true) {
          setTournament(data.data.list);
        } else {
          console.error("Failed to fetch tournaments");
        }
      })
      .catch((err) => {
        console.error("Error fetching tournaments:", err);
      });
  };

  const AuctionAdd = (event) => {
    event.preventDefault();
    const payload = new FormData();
    payload.append("auc_name", formData.auc_name);
    payload.append("auc_date", formData.auc_date);
    payload.append("auc_time", formData.auc_time);
    payload.append("points_per_team", formData.points_per_team);
    payload.append("base_bid", formData.base_bid);
    payload.append("bid_increase", formData.bid_increase);
    payload.append("max_players", formData.max_players);
    payload.append("min_players", formData.min_players);
    payload.append("season", formData.season);
    payload.append("tour_id", formData.Tornament);
    if (selectedImage) {
      payload.append("image", selectedImage);
    }

    axios
      .post("https://lunarsenterprises.com:3007/cricket/add/auction", payload, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => {
        if (response.data.result) {
          toast.success(
            response.data.message || "Auction created successfully!"
          );
          Navigate("/Auction");
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error in AuctionAdd:", error);
        toast.error("Failed to create auction. Please try again.");
      });
  };

  return (
    <div className="add-auction-body">
      <div className="add-auction-container">
        <h1 className="add-auction-title">CREATE AUCTION</h1>
        <form className="add-auction-form" onSubmit={AuctionAdd}>
          <label htmlFor="auction-logo">Auction Logo</label>
          <div className="auction-team-add__image-upload">
            {selectedImage ? (
              <img
                src={URL.createObjectURL(selectedImage)}
                alt="Auction Logo"
                className="auction-team-add__image-preview"
              />
            ) : (
              <div className="auction-team-add__image-placeholder">
                Upload Auction Logo
              </div>
            )}
            <input
              type="file"
              accept="image/*"
              className="auction-team-add__image-input"
              onChange={handleImageChange}
            />
          </div>

          <div className="add-auction-form-row">
            <div className="add-auction-form-group">
              <label htmlFor="season">Season</label>
              <select
                id="season"
                name="season"
                className="add-auction-select"
                value={formData.season}
                onChange={handleInputChange}
              >
                <option value="">--select--</option>
                {[...Array(10).keys()].map((num) => (
                  <option key={num + 1} value={num + 1}>
                    {num + 1}
                  </option>
                ))}
              </select>
            </div>
            <div className="add-auction-form-group">
              <label htmlFor="Tornament">Tornament</label>
              <select
                id="Tornament"
                name="Tornament"
                className="add-auction-select"
                value={formData.Tornament}
                onChange={handleInputChange}
              >
                <option value="">--select--</option>
                {tournament.map((item) => (
                  <option key={item.tr_id} value={item.tr_id}>
                    {item.tr_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="add-auction-form-group">
            <label htmlFor="auc_name">Auction Name*</label>
            <input
              type="text"
              id="auc_name"
              name="auc_name"
              className="add-auction-input"
              placeholder="Auction Name"
              value={formData.auc_name}
              onChange={handleInputChange}
            />
          </div>
          {[
            { id: "auc_date", label: "Auction Date*", type: "date" },
            { id: "auc_time", label: "Auction Time", type: "time" },
            {
              id: "points_per_team",
              label: "Points Per Team*",
              type: "number",
            },
            { id: "base_bid", label: "Base Bid*", type: "number" },
            { id: "bid_increase", label: "Bid Increase by*", type: "number" },
            {
              id: "max_players",
              label: "Player Per Team (Max Limit)*",
              type: "number",
            },
            {
              id: "min_players",
              label: "Player Per Team (Min Limit)*",
              type: "number",
            },
          ].map((field) => (
            <div className="add-auction-form-group" key={field.id}>
              <label htmlFor={field.id}>{field.label}</label>
              <input
                type={field.type}
                id={field.id}
                name={field.id}
                className="add-auction-input"
                placeholder={field.label}
                value={formData[field.id]}
                onChange={handleInputChange}
              />
            </div>
          ))}

          <button type="submit" className="add-auction-submit-btn">
            SUBMIT
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddAuction;
