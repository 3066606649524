import React, { useEffect, useState } from "react";
import "../styles/AuctionPlayer.css";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumbs from "../components/Breadcrumbs";
import axios from "axios";

function AuctionPlayer() {
  const navigate = useNavigate();
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const id = pathSegments[pathSegments.length - 1];
  const [status, setStatus] = useState(null);
  const [player, setPlayer] = useState([]);
  useEffect(() => {
    TeamList();
  }, [status]);
  const TeamList = () => {
    axios({
      url: "https://lunarsenterprises.com:3007/cricket/individual/list",
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        i_main_type: id,
        i_sale_status: status,
      },
    })
      .then((data) => {
        if (data.data.result === true) {
          setPlayer(data.data.list);
        } else {
          console.log("not working");
        }
      })
      .catch((err) => {
        console.error("Error fetching teams:", err);
      });
  };
  const baseurl = "https://lunarsenterprises.com:3007/";
  return (
    <div className="auction-player-container">
      <Breadcrumbs />
      <div className="auction-player-header">
        {/* <h1>BETTING FOR ANTONY</h1> */}
        <h2>PLAYERS</h2>
      </div>
      <div className="auction-player-footer">
        <span>{player?.length} PLAYERS</span>
      </div>
      <div className="auction-player-filters">
        <div className="auction-player-filter-item">
          {/* <input type="text" placeholder="Pl.No / Name / Mob.No" /> */}
        </div>
        <div className="auction-player-filter-item">
          <select onChange={(e) => setStatus(e.target.value)}>
            <option>Status</option>
            <option value="sold">Sold</option>
            <option value="unsold">Unsold</option>
          </select>
        </div>
        {/* <div className="auction-player-filter-item">
          <select>
            <option>Category A</option>
            <option>Category B</option>
          </select>
        </div> */}
        <button className="auction-player-filter-button">FILTER</button>
      </div>
      <div className="auction-player-list">
        {player?.map((player) => (
          <div key={player.i_id} className="auction-player-card">
            <div className="auction-player-info">
              <div className="auction-player-name">
                <img
                  src={baseurl + player.i_profile_pic}
                  alt="Auction Logo"
                  className="auction-player-image"
                />
              </div>
              <div className="auction-player-name">{player.i_name}</div>
              <div className="auction-player-age">Age: {player.i_age}</div>
              <div className="auction-player-details">{player.i_role}</div>
              <div className="auction-player-details">
                {player.i_batting_style}
              </div>
              <div className="auction-player-details">
                {player.i_bowiling_style}
              </div>
              <div className="auction-player-details">
                Wicket Keeper: {player.i_wicket_keeper}
              </div>
            </div>
            <div className="auction-player-price">
              {player.price ? (
                <>
                  <span className="price">Points : {player.price}</span>
                  <div className="sold-to">SOLD TO: {player.soldTo}</div>
                </>
              ) : (
                <span className="not-sold">
                  {player.i_sale_status ? player.i_sale_status : "Not Sold"}
                </span>
              )}
            </div>
            <div className="auction-player-footer">
              <button
                onClick={() => navigate(`/AuctionPlayerEdit/${player.i_id}`)}
              >
                Edit
              </button>
              {/* <button>Delete</button> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AuctionPlayer;
