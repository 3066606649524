import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../styles/subdomain.css";

const SubdomainList = () => {
  const [users, setUsers] = useState([
    { id: 1, name: "John Doe", role: "Admin", email: "john@example.com" },
    { id: 2, name: "Jane Smith", role: "User", email: "jane@example.com" },
  ]);

  const handleRemove = (id) => {
    setUsers(users.filter((user) => user.id !== id));
  };

  return (
    <div>
      <h1 className="subdomain-h1">User List</h1>
      <Link to="/addsubdomain">
        <button className="subdomain-add-btn">Add User</button>
      </Link>
      <table className="subdomain-table">
        <thead>
          <tr>
            <th className="subdomain-th">Name</th>
            <th className="subdomain-th">Role</th>
            <th className="subdomain-th">Email</th>
            <th className="subdomain-th">Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td className="subdomain-td">{user.name}</td>
              <td className="subdomain-td">{user.role}</td>
              <td className="subdomain-td">{user.email}</td>
              <td className="subdomain-td">
                <Link to={`/editsubdomain/${user.id}`}>
                  <button className="subdomain-edit-btn">Edit</button>
                </Link>
                <button
                  onClick={() => handleRemove(user.id)}
                  className="subdomain-delete-btn"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SubdomainList;
