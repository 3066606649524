// CalendarComponent.js
import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "../styles/CalendarComponent.css"; // Custom responsive styles

const CalendarComponent = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [bookings, setBookings] = useState({});
  const [engagements, setEngagements] = useState({});

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const addBooking = () => {
    if (selectedDate) {
      setBookings({ ...bookings, [selectedDate.toDateString()]: "Booking" });
    }
  };

  const addEngagement = () => {
    if (selectedDate) {
      setEngagements({
        ...engagements,
        [selectedDate.toDateString()]: "Engagement",
      });
    }
  };

  const tileContent = ({ date, view }) => {
    if (view === "month") {
      const dateString = date.toDateString();
      if (bookings[dateString]) {
        return <div className="booking-dot"></div>;
      }
      if (engagements[dateString]) {
        return <div className="engagement-dot"></div>;
      }
    }
  };

  return (
    <div className="calendar-container">
      <Calendar
        onChange={handleDateChange}
        value={selectedDate}
        tileContent={tileContent}
      />
      <div className="actions">
        <button onClick={addBooking}>Add Booking</button>
        <button onClick={addEngagement}>Add Engagement</button>
      </div>
      {selectedDate && (
        <p>
          Selected Date: {selectedDate.toDateString()} <br />
          Status:{" "}
          {bookings[selectedDate.toDateString()] ||
            engagements[selectedDate.toDateString()] ||
            "None"}
        </p>
      )}
    </div>
  );
};

export default CalendarComponent;
