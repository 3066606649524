import React from "react";
import { Routes, Route } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import DashboardPage from "./pages/DashboardPage";
import AuthLayout from "./layouts/AuthLayout";
import MainLayout from "./layouts/MainLayout";
import { useAuth } from "./contexts/AuthContext";
import MatchesPage from "./pages/MatchesPage";
import PlayersPage from "./pages/PlayersPage";
import TeamsPage from "./pages/TeamsPage";
import ForgotPassword from "./pages/ForgotPassword";
import MatchForm from "./pages/MatchForm";
import SpinPage from "./pages/SpinPage";
import GalleryPage from "./pages/GalleryPage";
import GalleryCreate from "./pages/GalleryCreate";
import SpinDetailsForm from "./pages/SpinDetailsForm";
import EditMatch from "./pages/EditMatch";
import EditMatchForm from "./pages/EditMatchForm";
import EnquiryList from "./pages/EnquiryList";
import Tournament from "./pages/Tornament";
import TournamentAdd from "./pages/TournamentAdd";
import TournamentEdit from "./pages/TournamentEdit";

import SpinWheel from "./pages/SpinWheel";
import AuctionPage from "./pages/AutionPage";
import AddAuction from "./pages/AddAuction";
import AuctionTeam from "./pages/AuctionTeam";

import AuctionCategory from "./pages/AuctionCategory";
import AddAuctionCategory from "./pages/AddAuctionCategory";
import AuctionPlayer from "./pages/AuctionPlayer";
import AuctionPlayersEdit from "./pages/AuctionPlayersEdit";
import EditAuction from "./pages/EditAuction";
import AuctionViewing from "./pages/AuctionViewing";
import MainAuctionPage from "./pages/MainAuctionPage";
import AuctionTeamEdit from "./pages/AuctionTeamEdit";
import AttendancePage from "./pages/AttendancePage";
import StockMaintenance from "./pages/StockMaintenance";
import CalendarComponent from "./components/CalendarComponent";
import SubdomainList from "./pages/SubdomainList";
import AddSubdomain from "./pages/AddSubdomain";

const AppRoutes = () => {
  const { authenticated } = useAuth();

  return (
    <>
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path="/" element={<LoginPage />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
        </Route>
        {authenticated && (
          <Route element={<MainLayout />}>
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/matches" element={<MatchesPage />} />
            <Route path="/Tournament" element={<Tournament />} />
            <Route
              path="/Tournament/TournamentAdd"
              element={<TournamentAdd />}
            />
            <Route path="/TournamentEdit/:id" element={<TournamentEdit />} />
            <Route path="/matches/create" element={<MatchForm />} />
            <Route path="/edit-match/:id" element={<EditMatchForm />} />
            <Route path="/edit/:id" element={<EditMatch />} />
            <Route path="/players" element={<PlayersPage />} />
            <Route path="/teams" element={<TeamsPage />} />
            <Route path="/TeamEdit" element={<TeamsPage />} />
            <Route path="/Spin" element={<SpinPage />} />
            <Route path="/SpinWheel" element={<SpinWheel />} />
            <Route path="/Spin/create" element={<SpinDetailsForm />} />
            <Route path="/Gallery" element={<GalleryPage />} />
            <Route path="/Gallery/create" element={<GalleryCreate />} />
            <Route path="/EnquiryList" element={<EnquiryList />} />
            <Route path="/Auction" element={<AuctionPage />} />
            <Route path="/AddAuction" element={<AddAuction />} />
            <Route path="/AuctionTeam" element={<AuctionTeam />} />
            <Route path="/AuctionTeam/:id" element={<AuctionTeam />} />
            <Route path="/AuctionTeamEdit/:id" element={<AuctionTeamEdit />} />
            <Route path="/AuctionCategory" element={<AuctionCategory />} />
            <Route
              path="/AddAuctionCategory"
              element={<AddAuctionCategory />}
            />
            <Route path="/AuctionPlayer" element={<AuctionPlayer />} />
            <Route path="/AuctionPlayer/:id" element={<AuctionPlayer />} />
            <Route path="/AuctionPlayerEdit" element={<AuctionPlayersEdit />} />
            <Route
              path="/AuctionPlayerEdit/:id"
              element={<AuctionPlayersEdit />}
            />
            <Route path="/EditAuction/:id" element={<EditAuction />} />
            <Route path="/AuctionViewing/:id" element={<AuctionViewing />} />
            <Route path="/MainAuctionPage/:id" element={<MainAuctionPage />} />
            <Route path="/Attendance" element={<AttendancePage />} />
            <Route path="/Stock" element={<StockMaintenance />} />
            <Route path="/calendar" element={<CalendarComponent />} />
            <Route path="/SubdomainList" element={<SubdomainList />} />
            <Route path="/addsubdomain" element={<AddSubdomain />} />
            <Route path="/editsubdomain/:id" element={<AddSubdomain />} />
          </Route>
        )}
      </Routes>
    </>
  );
};

export default AppRoutes;
