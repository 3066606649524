import React, { useEffect, useState } from "react";
import "../styles/PlayerCard.css";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import { ThreeDots } from "react-loader-spinner";
import ConfirmationModel from "../components/ConfirmationModel";

const PlayerCard = ({ player, onDelete }) => {
  const baseurl = "https://lunarsenterprises.com:3007/";

  return (
    <div className="player-card">
      <button className="delete-button" onClick={() => onDelete(player.i_id)}>
        <DeleteIcon />
      </button>
      <h2>{player.i_name}</h2>
      <div className="player-avatar-container">
        <img
          src={baseurl + player.i_profile_pic}
          alt={player.i_name}
          className="player-avatar"
        />
      </div>
      <p>
        {player.i_sale_status ? player.i_sale_status : ""}
        {player.i_sale_bid ? `(${player.i_sale_bid})` : ""}
      </p>
      <p className="text-para">
        Selected Team:{player.i_bid_team ? player.i_bid_team : ""}
      </p>
      <p className="text-para">
        Team:{player.i_current_team ? player.i_current_team : ""}
      </p>
      <p className="text-para">
        Role: {player.i_role?.charAt(0) + player.i_role?.slice(1).toLowerCase()}
      </p>
      <p className="text-para">
        Batting:{" "}
        {player.i_batting_style?.charAt(0) +
          player.i_batting_style?.slice(1).toLowerCase()}
      </p>
      <p className="text-para">
        Bowling:{" "}
        {player.i_bowiling_style?.charAt(0) +
          player.i_bowiling_style?.slice(1).toLowerCase()}
      </p>
      <p className="text-para">WK Keeper: {player?.i_wicket_keeper}</p>
      <p className="text-para">Mobile: {player.i_mobile}</p>
    </div>
  );
};
const PlayersPage = () => {
  const [type, setType] = useState("");
  const [teamOptions, setTeamOptions] = useState([]);
  const [download, setDownload] = useState("");
  const [players, setPlayers] = useState([]);
  const [selectedRole, setSelectedRole] = useState("All");
  const [loader, setLoader] = useState(false);
  const [loaderExcel, setLoaderExcel] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedPlayerId, setSelectedPlayerId] = useState(null);

  useEffect(() => {
    PlayerList();
    TournamentList();
  }, []);

  useEffect(() => {
    PlayerList();
  }, [type, selectedRole]);

  const PlayerList = () => {
    setPlayers([]);
    axios({
      url: "https://lunarsenterprises.com:3007/cricket/individual/list",
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        i_main_type: type,
        i_sale_status: selectedRole,
      },
    })
      .then((data) => {
        if (data.data.result === true) {
          setPlayers(data.data.list);
        } else {
          console.log("not working");
        }
      })
      .catch((err) => {
        console.error("Error fetching teams:", err);
      });
  };

  const downloadpdf = () => {
    setLoader(true);
    axios({
      url: "https://lunarsenterprises.com:3007/cricket/individual/list",
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        i_main_type: type,
        i_sale_status: selectedRole,
        format: "pdf",
      },
    })
      .then((data) => {
        if (data.data.result === true) {
          onButtonClick(data.data.pdf);
        } else {
          setLoader(false);
          console.log("not working");
        }
      })
      .catch((err) => {
        setLoader(false);
        console.error("Error fetching teams:", err);
      });
  };
  const downloadexcel = () => {
    setLoaderExcel(true);
    axios({
      url: "https://lunarsenterprises.com:3007/cricket/individual/list",
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        i_main_type: type,
        i_sale_status: selectedRole,
        format: "excel",
      },
    })
      .then((data) => {
        if (data.data.result === true) {
          onButtonClickExcel(data.data.excel);
        } else {
          setLoaderExcel(false);
          console.log("not working");
        }
      })
      .catch((err) => {
        setLoaderExcel(false);
        console.error("Error fetching teams:", err);
      });
  };
  const TournamentList = () => {
    axios({
      url: "https://lunarsenterprises.com:3007/cricket/list/tour",
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((data) => {
        if (data.data.result === true) {
          setTeamOptions(data.data.list);
        } else {
          console.log("not working");
        }
      })
      .catch((err) => {
        console.error("Error fetching teams:", err);
      });
  };

  const today = new Date().toISOString().split("T")[0];
  const upcomingTournament = teamOptions.filter((match) => {
    const startDate = new Date(match?.tr_start_date)
      .toISOString()
      .split("T")[0];
    const endDate = new Date(match?.tr_end_date).toISOString().split("T")[0];

    return startDate >= today || (startDate <= today && endDate >= today);
  });

  const handleExport = () => {
    downloadpdf();
  };
  const handleExportexcel = () => {
    downloadexcel();
  };
  const handleFilterChange = (e) => {
    setSelectedRole(e.target.value);
  };

  const onButtonClick = async (data) => {
    try {
      const response = await axios.get(data, {
        responseType: "blob",
      });

      const pdfBlob = new Blob([response.data], { type: "application/pdf" });

      const url = window.URL.createObjectURL(pdfBlob);

      const tempLink = document.createElement("a");
      tempLink.href = url;
      tempLink.setAttribute("download", `Registeration.pdf`);
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(url);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error downloading PDF:", error);
    }
  };
  const onButtonClickExcel = async (data) => {
    console.log(data, "data");

    if (data) {
      const headers = { "Content-Type": "blob" };
      const config = {
        method: "GET",
        url: data,
        responseType: "arraybuffer",
        headers,
      };

      try {
        const response = await axios(config);
        const outputFilename = `Registration${Date.now()}.xls`;
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", outputFilename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
        setLoaderExcel(false);
      } catch (error) {
        setLoaderExcel(false);
        console.error("Error downloading the file:", error);
      }
    }
  };

  const onDeleteConfirm = (playerId) => {
    console.log(playerId, "selectedPlayerId");

    setSelectedPlayerId(playerId);
    setShowConfirmModal(true);
  };

  const handleDelete = (response) => {
    if (response.isConfirm) {
      axios
        .post("https://lunarsenterprises.com:3007/cricket/individual/delete", {
          i_id: selectedPlayerId,
        })
        .then((response) => {
          if (response.data.result) {
            setPlayers(
              players.filter((player) => player.i_id !== selectedPlayerId)
            );
          } else {
            alert(response.data.message);
          }
        })
        .catch((err) => console.error("Error deleting player:", err));
    }
    setShowConfirmModal(false);
  };

  return (
    <div>
      <div className="header-container">
        <h1>Players</h1>
        <div className="filter-export-container">
          <select onChange={(e) => setType(e.target.value)} value={type}>
            <option value={""}>Select tournament</option>
            {upcomingTournament.map((teamName, index) => (
              <option key={index} value={teamName.tr_id}>
                {teamName.tr_name}
              </option>
            ))}
          </select>
          <select onChange={handleFilterChange} value={selectedRole}>
            <option value="All">All</option>
            <option value="sold">sold</option>
            <option value="unsold">unsold</option>
            <option value="coaching">Coaching</option>
          </select>
          <button disabled={loader} onClick={handleExport}>
            {loader ? (
              <ThreeDots visible height="20" width="20" color="#0c0773" />
            ) : (
              "Export pdf"
            )}
          </button>
          <button disabled={loader} onClick={handleExportexcel}>
            {loaderExcel ? (
              <ThreeDots visible height="20" width="20" color="#0c0773" />
            ) : (
              "Export excel"
            )}
          </button>
        </div>
      </div>
      <div className="player-list">
        {players.length > 0 ? (
          players.map((player) => (
            <PlayerCard
              key={player.i_id}
              player={player}
              onDelete={onDeleteConfirm}
            />
          ))
        ) : (
          <div className="no-players-found">No Players Found</div>
        )}
      </div>
      {showConfirmModal && (
        <ConfirmationModel
          id={selectedPlayerId}
          callback={handleDelete}
          onClose={() => setShowConfirmModal(false)}
        />
      )}
    </div>
  );
};

export default PlayersPage;
