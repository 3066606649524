import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import {
  FaTachometerAlt,
  FaUserFriends,
  FaCalendarAlt,
  FaTrophy,
  FaSpinner,
  FaFutbol,
  FaUsers,
  FaChartBar,
  FaImages,
  FaSignOutAlt,
} from "react-icons/fa";
import "../styles/Sidebar.css";

const Sidebar = ({ isOpen }) => {
  const { setAuthenticated } = useAuth();

  const handleLogout = () => {
    setAuthenticated(false);
    window.location.href = "/";
  };

  return (
    <div className={`sidebar-container ${isOpen ? "" : "closed"}`}>
      <Link to="/dashboard" className="sidebar-link">
        <FaTachometerAlt className="sidebar-icon" /> Dashboard
      </Link>
      <Link to="/Attendance" className="sidebar-link">
        <FaCalendarAlt className="sidebar-icon" /> Attendance
      </Link>
      <Link to="/Stock" className="sidebar-link">
        <FaCalendarAlt className="sidebar-icon" /> Stock
      </Link>
      <Link to="/calendar" className="sidebar-link">
        <FaCalendarAlt className="sidebar-icon" /> calendar
      </Link>
      <Link to="/Tournament" className="sidebar-link">
        <FaTrophy className="sidebar-icon" /> Tournament
      </Link>
      <Link to="/matches" className="sidebar-link">
        <FaFutbol className="sidebar-icon" /> Matches
      </Link>
      <Link to="/players" className="sidebar-link">
        <FaUserFriends className="sidebar-icon" /> Players
      </Link>
      <Link to="/teams" className="sidebar-link">
        <FaUsers className="sidebar-icon" /> Teams
      </Link>
      <Link to="/Spin" className="sidebar-link">
        <FaSpinner className="sidebar-icon" /> Create Spin
      </Link>
      <Link to="/SpinWheel" className="sidebar-link">
        <FaSpinner className="sidebar-icon" /> Spin Wheel
      </Link>
      <Link to="/Auction" className="sidebar-link">
        <FaChartBar className="sidebar-icon" /> Auction
      </Link>
      <Link to="/Gallery" className="sidebar-link">
        <FaImages className="sidebar-icon" /> Gallery
      </Link>
      <Link to="/SubdomainList" className="sidebar-link">
        <FaUserFriends className="sidebar-icon" /> SubdomainList
      </Link>
      <button className="logout-button" onClick={handleLogout}>
        <FaSignOutAlt className="sidebar-icon" /> Logout
      </button>
    </div>
  );
};

export default Sidebar;
