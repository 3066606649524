import React, { useEffect, useState } from "react";
import "../styles/AuctionTeamEdit.css";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

function AuctionTeamEdit() {
  const location = useLocation();
  const Navigate = useNavigate();
  const pathSegments = location.pathname.split("/");
  const id = pathSegments[pathSegments.length - 1];
  const [TeamView, setTeamView] = useState(null);
  const [formData, setFormData] = useState({});
  useEffect(() => {
    AuctionTemView();
  }, []);
  const AuctionTemView = () => {
    axios({
      url: "https://lunarsenterprises.com:3007/cricket/view/team",
      headers: {
        Accept: "application/json",
      },
      method: "POST",
      data: {
        team_id: id,
      },
    })
      .then((data) => {
        if (data.data.result) {
          const teamData = data.data?.list[0];
          setTeamView(teamData);
          setFormData(teamData);
        } else {
          console.log(data?.data?.message, "dataaa");
        }
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    axios({
      url: "https://lunarsenterprises.com:3007/cricket/edit/team",
      headers: {
        Accept: "application/json",
      },
      method: "POST",
      data: {
        member_count: formData.t_act_count,
        t_id: JSON.parse(id),
        max_bid: formData.t_bidvalue,
      },
    })
      .then((response) => {
        if (response.data.result) {
          console.log("Player details updated successfully!");
          Navigate(`/AuctionTeam/${response.data.auc_id}`);
        } else {
          console.log("Failed to update player details.");
        }
      })
      .catch((err) => {
        console.error("Error updating player details:", err);
      });
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const baseurl = "https://lunarsenterprises.com:3007/";
  return (
    <div className="auction-team-edit">
      <h2 className="edit-title">EDIT TEAM</h2>
      <form className="edit-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Team Logo</label>
          <div className="team-logo">
            <img src={baseurl + TeamView?.t_logo} alt="Team Logo" />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>Team Name *</label>
            <input
              type="text"
              placeholder="Enter team name"
              value={formData?.t_name || ""}
              disabled
            />
          </div>
          <div className="form-group">
            <label>Player Count</label>
            <input
              type="text"
              id="t_act_count"
              name="t_act_count"
              placeholder="Enter Player Count"
              value={formData.t_act_count}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>Maximum Bid Value *</label>
            <input
              type="number"
              id="t_bidvalue"
              name="t_bidvalue"
              placeholder="Enter Bid Value"
              value={formData.t_bidvalue}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <button type="submit" className="submit-button">
          SUBMIT
        </button>
      </form>
    </div>
  );
}

export default AuctionTeamEdit;
