import React, { useEffect, useState } from "react";
import "../styles/AuctionPlayersEdit.css";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

const AuctionPlayersEdit = () => {
  const location = useLocation();
  const Navigate = useNavigate();
  const pathSegments = location.pathname.split("/");
  const id = pathSegments[pathSegments.length - 1];
  const [playerView, setPlayerView] = useState(null);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    AuctionPlayrView();
  }, []);

  const AuctionPlayrView = () => {
    axios({
      url: "https://lunarsenterprises.com:3007/cricket/view/auc-player",
      headers: {
        Accept: "application/json",
      },
      method: "POST",
      data: {
        p_id: id,
      },
    })
      .then((data) => {
        if (data.data.result) {
          const playerData = data.data?.list[0];
          setPlayerView(playerData);
          setFormData(playerData);
        } else {
          console.log(data?.data?.message, "dataaa");
        }
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    try {
      axios({
        url: "https://lunarsenterprises.com:3007/cricket/edit-auc/player",
        headers: {
          Accept: "application/json",
        },
        method: "POST",
        data: {
          age: formData.i_age,
          mobile_no: formData.i_mobile,
          jersy_no: formData?.i_jersey_no,
          trouser_size: formData.i_trouser_size,
          base_value: JSON.parse(formData?.i_min_bidvalue),
          t_shirt_size: formData.i_t_shirt_size,
          jersy_name: formData.i_jersey_name,
          details: formData.details,
          i_id: JSON.parse(id),
        },
      })
        .then((response) => {
          if (response.data.result) {
            console.log("Player details updated successfully!");
            Navigate(`/AuctionPlayer/${response.data.tour_id}`);
          } else {
            console.log("Failed to update player details.");
          }
        })
        .catch((err) => {
          console.error("Error updating player details:", err);
        });
    } catch (error) {
      console.error("Error parsing input data:", error);
    }
  };

  const baseurl = "https://lunarsenterprises.com:3007/";
  return (
    <div className="edit-players-container">
      <h1 className="title">Edit Players</h1>
      <form className="edit-players-form" onSubmit={handleSubmit}>
        <div className="form-group profile-pic-section">
          <label className="label">Profile Pic</label>
          <div className="profile-pic">
            <img
              src={baseurl + playerView?.i_profile_pic}
              style={{ height: "100px", width: "100px", borderRadius: "100%" }}
              alt="Profile"
            />
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="i_name">Name *</label>
          <input
            type="text"
            id="i_name"
            name="i_name"
            placeholder="Enter Name"
            value={formData.i_name || ""}
            onChange={handleInputChange}
            disabled
          />
        </div>

        <div className="form-group">
          <label htmlFor="i_mobile">Mobile No</label>
          <input
            type="text"
            id="i_mobile"
            name="i_mobile"
            placeholder="Enter Mobile No"
            value={formData.i_mobile || ""}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="i_age">Age</label>
          <input
            type="number"
            id="i_age"
            name="i_age"
            placeholder="Enter Age"
            value={formData.i_age || ""}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="i_role">Role</label>
          <input
            type="text"
            id="i_role"
            name="i_role"
            placeholder="Enter Role"
            value={formData.i_role || ""}
            onChange={handleInputChange}
            disabled
          />
        </div>

        <div className="form-group">
          <label htmlFor="i_batting_style">
            Specification 1 (batting style)
          </label>
          <input
            type="text"
            id="i_batting_style"
            name="i_batting_style"
            placeholder="Enter Specification 1"
            value={formData.i_batting_style || ""}
            onChange={handleInputChange}
            disabled
          />
        </div>

        <div className="form-group">
          <label htmlFor="spec2">Specification 2 (Bowling style)</label>
          <input
            type="text"
            id="i_bowiling_style"
            name="i_bowiling_style"
            placeholder="Enter Specification 2"
            value={formData.i_bowiling_style || ""}
            onChange={handleInputChange}
            disabled
          />
        </div>

        <div className="form-group">
          <label htmlFor="spec3">Wicket Keeper</label>
          <input
            type="text"
            id="i_wicket_keeper"
            name="i_wicket_keeper"
            placeholder="Enter Wicket Keeper"
            value={formData.i_wicket_keeper || ""}
            onChange={handleInputChange}
            disabled
          />
        </div>

        <div className="form-group">
          <label htmlFor="tshirt-size">T-shirt Size</label>
          <input
            type="text"
            id="i_t_shirt_size"
            name="i_t_shirt_size"
            placeholder="Enter T-shirt Size"
            value={formData.i_t_shirt_size || ""}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="jersey-number">
            Jersey Number(Don't Add 0 in front)
          </label>
          <input
            type="text"
            id="i_jersey_no"
            name="i_jersey_no"
            placeholder="Enter Jersey Number"
            value={formData.i_jersey_no || ""}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="jersey-name">Jersey Name</label>
          <input
            type="text"
            id="i_jersey_name"
            name="i_jersey_name"
            placeholder="Enter Jersey Name"
            value={formData.i_jersey_name || ""}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="trouser-size">Trouser Size</label>
          <input
            type="text"
            id="i_trouser_size"
            name="i_trouser_size"
            placeholder="Enter Trouser Size"
            value={formData.i_trouser_size || ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="Detail">Detail</label>
          <input
            type="text"
            id="details"
            name="details"
            placeholder="Enter Detail"
            value={formData.details || ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="Base Value">Base Value</label>
          <input
            type="number"
            id="i_min_bidvalue"
            name="i_min_bidvalue"
            placeholder="Enter Base Value"
            value={formData.i_min_bidvalue || ""}
            onChange={handleInputChange}
          />
        </div>

        <button type="submit" className="submit-button">
          Save
        </button>
      </form>
    </div>
  );
};

export default AuctionPlayersEdit;
